<template>
 <div class="wrap">
  <div class="header">
    <NavigateBar :title="'写作业'" @handleBack="handleBack"/>
  </div>
  <ScorePage v-if="showSubmitPaper" :getScore="getScore" :totalScore="totalScore"/>
  <div class="main" @touchstart="touchstart" @touchend="touchend" v-else>
    <div class="score" v-if="query.type == 'check'">
      <div class="total">
        总分<span>{{ totalScore }}</span>
      </div>
      <div class="get">
        得分<span>{{ getScore }}</span>
      </div>
    </div>
    <div class="taking">
      <div class="taking-content">
        <QuestionTitle :question="curQuestionData" :cur="curQuestionNum + 1" :total="questionList.length" />
        <TeacherFile :question="curQuestionData"/>
        <div v-show="question.type !== 4" class="list">
          <QuestionOption
            v-for="(item, index) in curQuestionData.option"
            :option="item"
            :key="item.key"
            :index="index"
            :question="curQuestionData"
            @click="handleClick(item)"
            :pageType="query.type"
            :userSelect="userSelect"
          />
        </div>
        <div class="textarea" v-show="curQuestionData.type === 4">
          <van-field
              v-model="userAnswer"
              rows="1"
              style="width:100%"
              :readonly="Boolean(query.type == 'check')"
              label-width="32px"
              :autosize="{minHeight: 140 }"
              label="回答"
              type="textarea"
              placeholder="请输入回答"
            />
        </div>
        <div class="attachment-list" v-if="attachmentList.length">
                  <div class="attachment-item" v-for="(item,index) of attachmentList" :key="index">
                    <img class="file-icon" src="../../assets/img/file.png" />
                    <div class="upload-status">
                      <div class="attachment-name">{{ item.title }}</div>
                      <div class="progress" v-if="!item.done && !item.err && item.progress">
                        <span>{{(item.progress.loaded/(1024*1024)).toFixed(2)}}M/{{(item.progress.total/(1024*1024)).toFixed(2)}}M</span>
                        <van-progress
                        :percentage="item.progress.percent*100"
                        :show-pivot	="false"
                        pivot-color="rgb(255,255,255)"
                        color="#10A675"
                        style="width:155px; margin:0 6px"></van-progress>
                        <span>{{(item.progress.speed/(1024*1024)).toFixed(2)}}m/s</span>
                      </div>
                      <div class="progress" v-else-if="item.done && !item.err && item.progress">
                        <span>大小：{{(item.progress.total/(1024*1024)).toFixed(2)}}M</span>
                      </div>
                      <div class="progress" v-else-if="item.size">
                        <span>大小：{{item.size}}M</span>
                      </div>
                      <div class="progress" v-else-if="item.err">
                        <span style="color:#F56C6C">上传失败，请删除重新上传</span>
                      </div>
                    </div>
                    <img class="close-icon" v-if="!Boolean(query.type == 'check')" src="../../assets/img/ico_guanbi@2x.png" @click="deleteAttachment(index)" />
                  </div>
          </div>
        <div class="add-attachment" @click="chooseFile" v-if="curQuestionData.type == 4 && (query.type == 'do' || query.type == 'edit')">
          <img src="../../assets/img/ico_add@2x.png" />
          <span>添加附件</span>
          <input type="file" style="display: none" ref="updateFile" @change="afterRead($event)" />
        </div>
        <QuestionParse 
        v-if="query.type == 'check'"
        :question="curQuestionData"
        :indexanawer='indexanawer'
        :comment="questionComment"
        :cur="curQuestionNum + 1"
        :total="questionList.length"
        />
        <CommentFile :file="commentFile" v-show="(curQuestionNum + 1) == questionList.length" />
        <div class="switch-question">
              <div class="switch-prev-question" @click="toPrevQuestion" v-if="!isFirst">
                <span>上一题</span>
              </div>
              <div class="switch-next-question" @click="toNextQuestion" v-if="!(isEnd && query.type == 'check')">
                <span>{{ isEnd?'提交':'下一题' }}</span>
              </div>
        </div>
        <van-dialog v-model="confirmShow"
        title="提示"
        :show-cancel-button="isAllDone"
        width="280px"
        @confirm="dialogConfirm"
        confirmButtonColor="#10a675">
          <div style="width:250px; text-align:center">
            {{dialogText}}
          </div>
          
        </van-dialog>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import NavigateBar from './navigateBar.vue';
import QuestionTitle from './components/questionTitle.vue'
import QuestionOption from './components/questionOption.vue'
import QuestionParse from './components/questionParse.vue'
import TeacherFile from './components/teacherFile.vue'
import CommentFile from './components/commentFile.vue'
import ScorePage from './components/scorePage.vue'
import COS from 'cos-js-sdk-v5'

import Vue from 'vue';
import { Progress, Dialog, Notify } from 'vant';

Vue.use(Progress);
Vue.use(Dialog);
export default {
  props: { },
  data () {
    return { 
      indexanawer: '',
			testType: 0, // 0 模拟考试 1 背题 2 顺序答题 3 查看试卷
			showFb: false, // 展示反馈弹窗
			uqid: undefined,
			number: undefined,
			set: 0,
			question: {},
			cid: '',
			touchX: 0,
			orderType: 0, // 答题模式 0	背题模式 1
			userSelect: [],
			timer: null,
			userAnswer: '', // 用户简答题答案,
			canChangeList: [0, 2],
			showSubmit: false,
			testId: undefined,
			countDown: '00:00:00',
			timer1: null,
			showTime: false,
			onlyShowFail: false, // 仅显示错题
			questionList: [],
      cos:{},
      attachmentList:[],
      confirmShow:false,
      curQuestionData:{},
      curQuestionNum:0,
      dialogText:'',
      isAllDone:true,
      questionComment:'',
      uploading:false,
      dialogType:1, // 提交作业 1   返回继续 2   退出页面  3
      showSubmitPaper:false,
      getScore:0,
      totalScore:0,
      commentFile:[]
    }
  },
  created(){
    this.initCos()
    const {payload}=this.$route.query
    this.query = JSON.parse(decodeURIComponent(payload))
    if(this.query.type == 'do'){
      this.getQuestionContent()
    }
    if(this.query.type == 'check' || this.query.type == 'edit'){
      this.getDoneQuestionContent()
    }
  },
  // beforeCreate () {
  //   this.$nextTick(()=>{
  //     document.body.setAttribute('style', 'background:#f6f6f6')
  //   })
  // },
  // beforeDestroy () {
  //   document.body.removeAttribute('style')
  // },
  components:{
    NavigateBar,
    QuestionTitle,
    QuestionOption,
    QuestionParse,
    TeacherFile,
    ScorePage,
    CommentFile
  },
  computed: { 
    showParse() {
			let flag = false;
			const { testType } = this;
			if (testType == 0) {
				flag = false
			} else {
				flag = true;
			}
			return flag;
		},
    isEnd () {
        return this.curQuestionNum+1 >= this.questionList.length
      },
    isFirst () {
      return this.curQuestionNum <= 0
    },
  },
	// watch: {
	// 	number() {
	// 		this.getDetail();
	// 	}
	// },
  watch:{
    curQuestionNum(val){
      this.userSelect=[]
      this.userAnswer = ''
      this.attachmentList=[]
      this.curQuestionData = this.questionList[val]
      if(this.curQuestionData.type != 4 && this.curQuestionData.user_answer){
        this.userSelect = this.curQuestionData.user_answer.split('')
        return
      }
      if(this.curQuestionData.type == 4){
        this.userAnswer = this.curQuestionData.user_answer
        this.analysis = this.curQuestionData.analysis
        this.attachmentList = this.curQuestionData.answer_file || []
        return
      }
    }
   },
  mounted() { },
  methods:{
    // c初始cos
    initCos(){
      this.cos = new COS({
      // getAuthorization 必选参数
      getAuthorization: function (options, callback) {
        // 异步获取临时密钥
        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
        const baseUrl = process.env.VUE_APP_API_ONLINE_SCHOOL

        var url = `${baseUrl}/common/index/getCosSign` // url替换成您自己的后端服务
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.onload = function (e) {
          try {
            var data = JSON.parse(e.target.responseText)
            var credentials = data.data.credentials
          } catch (e) {
            console.log(e);
          }
          if (!data || !credentials) {
            return console.error(
              'credentials invalid:\n' + JSON.stringify(data, null, 2)
            )
          }
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            SecurityToken: credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: data.data.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: data.data.expiredTime, // 时间戳，单位秒，如：1580000000
          })
        }
        xhr.send()
      },
    })
    },
      // 获取当前题目内容请求
    async getQuestionContent () {
        const data = { qt_id: this.query.qt_id }
        const res = await this.$api.post('/user/index/dotask', data)
        if (res.data.code === 200) {
          this.questionList = res.data.data
          this.questionList.forEach(item=>{
            if(item.type == 3){
              item.option = [{key:'1',value:'正确'},{key:'2',value:'错误'}]
            }
          })
          if(this.questionList.length){
            this.curQuestionData = this.questionList[0]
          }
          this.totalScore = this.questionList.reduce((total,item)=>{return total+item.score},0)

        }
    },
    async getDoneQuestionContent () {
        const data = { qt_id: this.query.qt_id, section_id:this.query.section_id, chapter_id:this.query.chapter_id, ucqid:this.query.ucqid}
        const res = await this.$api.post('/user/index/see', data)
        if (res.data.code === 200) {
          this.questionList = res.data.data.answer_list.map(item=>{
              item.id = item.qid
              delete item.qid
              return item
          })
          this.questionList.forEach(item=>{
            if(item.type !== 4){
              const correctAnswer = item.answer.split('').sort().join()
              const userAnswer = item.user_answer.split('').sort().join()
              if(correctAnswer == userAnswer){
                item.row = 1
              }
            }
            if(item.type == 3){
              item.option = [{key:'1',value:'正确'},{key:'2',value:'错误'}]
            }
          })
          this.totalScore = res.data.data.comment.total
          this.getScore = res.data.data.comment.user_score
          if(this.questionList.length){
            if(this.query.type == 'check' && this.query.status == 2){
              this.curQuestionData = this.questionList[this.questionList.length-1]
              this.curQuestionNum = this.questionList.length-1
              this.questionComment = res.data.data.comment.comment 
              this.commentFile = res.data.data.comment.files
            }
            this.curQuestionData = this.questionList[0]
          }
          if(this.curQuestionData.type != 4 && this.curQuestionData.user_answer){
            this.userSelect = this.curQuestionData.user_answer.split('')
            return
          }
          if(this.curQuestionData.type == 4 && this.curQuestionData.user_answer){
            this.userAnswer = this.curQuestionData.user_answer
            this.analysis = this.curQuestionData.analysis
            this.attachmentList = this.curQuestionData.answer_file || []
            return
          }
        }
      },   
    async handleClick(item) {
			if (this.showLoading) return;
			// this.userSelect = item.key === this.userSelect ? '' : item.key;
			if (this.curQuestionData.answer && this.query.type == 'check') return;
			// debugger
			const userSelect = this.userSelect;
			if (this.curQuestionData.type === 2) {
				if (userSelect.includes(item.key)) {
					this.userSelect = userSelect.filter(t => item.key !== t);
				} else {
					this.userSelect.push(item.key);
				}
			} else {
				this.userSelect = [item.key];
			}
		},
    chooseFile() {
      this.$refs.updateFile.click()
      },
          // 上传头像
      afterRead(e) {
      // 此时可以自行将文件上传至服务器
      let file = e.target.files[0]
      console.log(file)
      this.attachmentList.push({title:file.name, done:false, err:false})
      this.uploading = true
      this.cos.putObject(
        {
          Bucket: 'tengface-live-user-1308721187',
          /* 填入您自己的存储桶，必须字段 */
          Region: 'ap-guangzhou',
          /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: new Date().getTime() + file.name,
          /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          onProgress: progressData=> {
            this.attachmentList.forEach((item,i)=>{
              if(item.title === file.name){
                this.$set(this.attachmentList[i], 'progress', progressData)
              }
            })
          },
        },
        (err, data) => {
          this.uploading = false
          if (data.statusCode === 200) {
            this.attachmentList.forEach((item,i)=>{
              if(item.title === file.name){
                this.$set(this.attachmentList[i], 'done', true)
                this.$set(this.attachmentList[i], 'url', `http://${data.Location}`)
              }
            })
          }
          if(err){
            this.attachmentList.forEach((item,i)=>{
              if(item.title === file.name){
                this.$set(this.attachmentList[i], 'err', true)
              }
            })
          }
        }
      )
    },
    deleteAttachment(index){
      this.uploading = false
      this.attachmentList.splice(index,1)
    },
    toPrevQuestion(){
      if(this.query.type == 'check'){
          this.curQuestionNum--
          return
        }
        if(this.uploading){
            Notify('请等待文件上传完成再操作')
          return
        }
        if(this.userSelect.length){
          this.questionList.forEach(item=>{
            if(item.id == this.curQuestionData.id){
              item.user_answer = this.userSelect.join('')
            }
          })
        }
        if(this.userAnswer){
          this.questionList.forEach(item=>{
            if(item.id == this.curQuestionData.id){
              item.user_answer = this.userAnswer
            }
          })
        }
        if(this.attachmentList.length){
          this.questionList.forEach(item=>{
            if(item.id == this.curQuestionData.id){
              item.answer_file= this.attachmentList.map(item=>{
                if(item.size){
                  return {
                    title:item.title,
                    url:item.url,
                    size:item.size
                  }
                }else{
                  return {
                    title:item.title,
                    url:item.url,
                    size:(item.progress.total/(1024*1024)).toFixed(2)
                  }
                }

              })
            }
          })
        }
        this.curQuestionNum--
    },
    toNextQuestion(){
      if(this.query.type == 'check'){
          this.curQuestionNum++
          return
        }
        if(this.uploading){
          Notify('请等待文件上传完成再操作')
          return
        }
        if(this.userSelect.length){
          this.questionList.forEach(item=>{
            if(item.id == this.curQuestionData.id){
              item.user_answer = this.userSelect.join('')
            }
          })
        }
        if(this.userAnswer){
          this.questionList.forEach(item=>{
            if(item.id == this.curQuestionData.id){
              item.user_answer = this.userAnswer
            }
          })
        }
        if(this.attachmentList instanceof Array && (this.attachmentList.length || this.attachmentList.length === 0)){
          this.questionList.forEach(item=>{
            if(item.id == this.curQuestionData.id){
              item.answer_file= this.attachmentList.map(item=>{
                if(item.size){
                  return {
                    title:item.title,
                    url:item.url,
                    size:item.size
                  }
                }else{
                  return {
                    title:item.title,
                    url:item.url,
                    size:(item.progress.total/(1024*1024)).toFixed(2)
                  }
                }

              })
            }
          })
        }
        if(this.isEnd){
          const result = this.questionList.some(item=> !item.user_answer)
          const endTime = new Date(this.query.end_time).getTime()
          const nowTime = new Date().getTime()
          if(result){
            this.dialogText = '当前有作业未填写，请填写后提交'
          }else{
            if(nowTime>endTime){
              this.dialogText="当前已过截止时间，提交后为迟交状态，确定还要提交吗？"
            }else{
              this.dialogText="请仔细检查作业，是否确认提交"
            }
          }
          this.dialogType = result? 2 : 1
          this.isAllDone = !result
          this.confirmShow = true
          return
        }
        this.curQuestionNum++
        
        // this.briefAnswer = ''
        // this.singleSelectRadio = ''
        // this.multipleList = []
    },
    handleBack(){
      if(this.showSubmitPaper || this.query.type == 'check'){
        this.$router.go(-1)
        return
      }
      this.dialogText = '返回将不会保存填写的内容，确定返回吗？'
      this.dialogType = 3
      this.confirmShow = true
    },
    dialogConfirm(){
      if(this.dialogType == 3){
        this.$router.back()
      }
      if(this.dialogType == 1){
        this.submitAnswer()
      }
    },
    async submitAnswer(){
      const content = this.questionList.map(item=>{
            if(item.type == 4){
            return{
              file:item.answer_file?JSON.stringify(item.answer_file) || "" : '',
              qid:item.id,
              title:item.title,
              answer:item.user_answer,
              type:item.type,
              score:0
            }
          }else{
            return{
              qid:item.id,
              title:item.title,
              answer:item.user_answer,
              type:item.type,
              file:item.answer_file?JSON.stringify(item.answer_file) || "" : '',
              score:[...item.user_answer].sort().toString() == [...item.answer].sort().toString()?item.score:0
            }
          }
        })
        this.getScore = content.reduce((total,item)=>{return total+item.score},0)
        const data = {
          cq_id: this.query.cq_id,
          content:JSON.stringify(content)
      }
      const res = await this.$api.post('/user/index/submit', data)
      if (res.data.code === 200) {
          this.showSubmitPaper = true
      }else{
          Notify(res.data.message)
      }
    },
    touchstart(e) {
			const { changedTouches } = e;
			const { clientX } = changedTouches[0];
			this.touchX = clientX;
		},
		touchend(e) {
			const { changedTouches } = e;
			const { clientX } = changedTouches[0];
			if (clientX - this.touchX >= 100 && !this.isFirst) {
				this.toPrevQuestion()
			} else if (this.touchX - clientX >= 100 && !this.isEnd) {
				this.toNextQuestion()
			}
			this.touchX = 0;
		},
  }
}
</script>

<style lang="scss" scoped>
.wrap{
  width: 100%;
  height: 100%;
  background: #f6f6f6;
}
.header{
    box-sizing: border-box;
    background: #fff;
    padding: 0 10px;
    // padding-bottom: 7px;
  }
.main{
  height: calc(100vh - 50px);
  // padding-bottom: 50px;
  background: #f6f6f6;
  .score{
    height: 40px;
    display: flex;
    background: #E7F6F1;
    box-sizing: border-box;
    padding: 0 10px;
    align-items: center;
    div{
      color: #202020;
      font-size: 14px;
      span{
        margin-left: 8px;
      }
    }
    .total{
      span{
        font-weight: 600;
      }
    }
    .get{
      margin-left: auto;
      span{
        font-size: 20px;
        color: #24AE81;
      }
    }
  }
}
.taking {
	box-sizing: border-box;
  background: #f6f6f6;
	.taking-content {
		padding: 16px 0 50px;
	}
}
.list {
	// padding: 0 0 20px 0;
}
.submit {
	width: 630rpx;
	height: 88rpx;
	line-height: 88rpx;
	text-align: center;
	background: linear-gradient(315deg, #ed3f10, #fb724d 100%);
	border-radius: 44rpx;
	margin: 60rpx auto;
	font-size: 32rpx;
	color: #ffffff;
}
.textarea {
	width: 355px;
	margin: 12px auto 0;
	// ::v-deep.u-textarea {
	// 	background-color: #fff;
	// }
}
.add-attachment{
  color: #0BB65D;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  font-size: 16px;
  cursor: pointer;
  img{
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
}
.attachment-list{
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  .attachment-item{
    // height: 64px;
    background: #ffffff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 16px 5px 6px;
    margin-bottom: 10px;
    border-radius: 4px;
    .attachment-name{
      max-width: 250px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap
    }
    .file-icon{
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
    .upload-status{
      .progress{
        display: flex;
        margin-top: 6px;
        align-items: center;
        max-width: 275px;
        ::v-deep .el-progress-bar__outer{
          background-color:#ffffff;
        }
        span{
          font-size: 12px;
          color: #888888;
        }
      }
    }
    .close-icon{
      width: 16px;
      height: 16px;
      margin-left: auto;
      cursor: pointer;
    }
  }
}
.switch-question{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  box-sizing: border-box;
  height: 50px;
  background: #ffffff;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  .switch-next-question{
    width: 92px;
    height: 34px;
    background: #10a675;
    border-radius: 17px;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    line-height: 34px;
    margin-left: auto;
  }
  .switch-prev-question{
    width: 92px;
    height: 34px;
    background: #EFF2F5;
    border-radius: 17px;
    font-size: 14px;
    text-align: center;
    color: #202020;
    line-height: 34px;
  }
}
::v-deep .van-dialog__content{
  display: flex;
  justify-content: center;
  margin: 16px 0;
}
::v-deep .van-dialog__header{
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
}
</style>