<template>
  <div class="liveBroadcast" :ref="'course_chapter' + 0">
    <!-- 第一部分 -->
    <div class="yuyuyu">
        <van-popup v-model="code" title="" style="border-radius: 10px;width: 320px;height: 210px;"  width="320px"  theme="round-button" showConfirmButton="false">
        <h3 style="text-align: center;" class="kechengduihuan">课程兑换码</h3>
      <p class="huoqulist">（兑换码请到相关老师处获取）</p>
      <form label-width="120px" style="margin-top: 30px;">
        
        <div style="width: 244px;margin: 0 auto;text-align: center;">
          <input style="text-align: left;" class="codeshuru" v-model="codeData" placeholder="请输入课程兑换码" />
          <div style="height: 20px;text-align: left;"><p class="yiguoqilist">{{this.errData}}</p></div>
        </div>
      </form>
      <div style="text-align: center;margin-top: 5px;">
        <button class="lijiduan" type="text" @click="useCdk()">立即兑换</button>
      </div>
    </van-popup>
      </div>
    <div class="headerBox">
      <div class="diyi">
        <img v-if="!VODData" class="courseCover" :src="cover" />
        <TFPlayer
          ref="tfPlayer"
          :videoPoster="cover"
          :VODData="VODData"
          :playType="'vod'"
          class="tfPlayer"
        />
        <div style="height: 90px; width: 100%">
          <div>
            <img
              class="fanhui"
              @click="goOff()"
              style="margin-top: 5px"
              src="../assets/images/fanhuizhong.png"
            />
          </div>
        </div>
      </div>
      <div class="debf">
        <p class="fafa">{{ name }}</p>
        <div>
          <div class="zhibosj">
            直播时间：{{ start_timeList.start_time }}
            <div v-if="numStatus == 1" class="rightStudy">
              <img src="../assets/img/guankan.png" />
              <span class="qishi">{{ studyNum }}人学习</span>
            </div>
          </div>
          <div class="mytestping" v-if="comment">我的评测：{{comment}}</div>
        </div>
      </div>
    </div>
    <div class="contentBox">
      <div class="lineBox"></div>
      <div class="dsanbf">
        <div>
          <p style="float: left" class="mulu">课程目录</p>
          <div class="banji" @click="showClass = true">
            切换班级: {{ currClassName }}
            <van-icon
              v-if="showClass == false"
              style="margin-left: -3px;"
              name="arrow"
            />
            <van-icon
              style="margin-left: -3px;"
              v-else-if="showClass == true"
              name="arrow-down"
            />
          </div>
          <div style="clear: both"></div>
        </div>
        <van-collapse
          style="margin-top: 0.43rem; padding: 0 0.43rem;"
          v-model="activeNames"
          :border="false"
        >
          <van-collapse-item
            class="zhangjielist"
            :id="'zhangjielist'+item.sectionId"
            :ref="'collapseId'+item.sectionId"
            v-for="(item, index) in optionsa"
            :key="item.id"
            :title="item.sectionName"
            :name="item.sectionId"
          >
            <div
              style="margin-top: 0.43rem"
              v-for="(item1, index) in item.chapters"
              :key="item1.id"
              :ref="'chapterJie' + item1.id"
            >
              <div style="width: 235px;">
                <span class="linyi">{{
                  index > 8 ? index + 1 : "0" + (index + 1)
                }}</span>
                <span v-if="item1.type === 3" class="huifanga">回放</span>
                <span v-if="item1.type === 2" class="huifanga">录播</span>
                <span
                  v-else-if="item1.type === 4|| item1.type === 1 && item1.status !== 3"
                  class="huifangb"
                  >直播</span
                >
                <span v-if="item1.status !== 3" class="chushia">{{
                  item1.name
                }}</span>
                <span v-else-if="item1.status === 3" class="chushialist">{{
                  item1.name
                }}</span>
                <span v-if="item1.show == 1" class="keduihuan">可兑换</span>
              </div>
              <div style="margin-top: 4px;">
                <span class="shijiand">{{ item1.start_time }}</span>
                <div
                
                  v-if="item1.status === 4 || item1.status === 2"
                  @click="liveAuth(item1,item1.show)"
                  class="jirzb"
                  >进入直播</div
                >
                <div v-if="item1.status === 3" class="jirzblist"
                  >直播已结束</div
                >
              </div>
             
              <div v-if="item1.videos.length>3&&item1.type === 3"  class="sort"><div  :class="sortimgs[item1.id]?'stbx stbx1':'stbx'" @click="sorta(item1.id)">{{ sortform[item1.id]==1?'升序':sortform[item1.id]==0?'降序':'排序' }}<img class="sortimg" v-if="!sortimgs[item1.id]" src="@/assets/images/sort.png" alt=""><img v-else class="sortimg" :src="sortimgs[item1.id]" alt="" ></div></div>
              <div
              @click="liveAutha(item.sectionId,item1, item2.id,item2,item,item1.show)"
                v-for="item2 in item1.videos"
                :key="item2.id"
              >
              <div class="videoItem">
                <div >
                  <img class="playBtn" src="../assets/images/bofang.png" />
                  <span :class="acterss === item2.id ? 'active' : ''" class="chushia">{{ item2.name }}</span>
                </div>
                <div class="time" >
                    <span style="margin-right:5px;margin-left: 25px;">{{ transformation(item2.duration) }}</span><span class="aglook" v-if="item2.is">上次看到</span></div>
              </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div
          v-if="showAll"
          @click="openAll"
          style="text-align: center; margin-top: 20px"
        >
          <span class="gengduo">查看更多目录</span>
          <img
            style="margin-left: 6px; width: 14px; vertical-align: -2px"
            src="../assets/img/xiangxiazhankai.png"
          />
        </div>
      </div>
      <div class="lineBox"></div>
      <div class="dsibf">
        <p class="mulu">课程介绍</p>
        <div style="background: #fff; height: 100%">
          <p style="margin: 20px 17px" class="kecheng_desc" v-html="desc"></p>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showClass"
      position="bottom"
      round
      :style="{ height: '50%' }"
    >
      <div>
        <p class="xuanban">请选择班级</p>
        <img
          @click="guanbiclassget()"
          class="guanbiclass"
          src="../assets/images/guanbilist.png"
          alt=""
        />
        <div
          v-for="item in options"
          :key="item.period"
          @click="classChange(item)"
        >
          <p
            class="luse"
            :style="{ color: item.select ? '#10A675' : '#000000' }"
          >
            {{ item.period }}
          </p>
          <div v-if="item.select" class="xuanzhong">
            <img
              style="height: 20px"
              src="../assets/images/xinzhuangjiehe.png"
              alt=""
            />
          </div>
          <div v-else class="xuanzhong">
            <img src="../assets/images/weixuanertu.png" alt="" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { ActionSheet, Toast, Popup } from "vant";
import TFPlayer from "tfplayer";
import { mapGetters } from "vuex";
import Vue from "vue";
import { ref } from "vue";
import { Dialog } from 'vant';
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(ActionSheet);
export default {
  name: "liveBroadcast",
  computed: {
    ...mapGetters({
      org_id: "user/getOrgId",
      fans_id : "user/getFansId",
    }),
  },
  data() {
    return {
      sortimgs: {},
      sortform:{},
      acterss:  null,
      domain: "test.tenghuiwangxiao.cn",
      name: "",
      desc: "",
      options: [],
      value: "",
      optionsa: [],
      optionsaAll: [],
      class_id: "",
      showAll: false,
      optionsaAlla: [],
      start_timeList: "",
      studyNum: 0,
      vodDomduration:'',
      numStatus: "",
      show: false,
      showClass: false,
      currClassName: "",
      cover: "",
      VODData: null,
      activeNames: [],
      playerType: "vod",
      currentPlayerData: null,
      vodTime:null,
      playStatus:'',
      vodDom:null,
      scrollTop:'',
      fileId:'',
      wx_h5_url: "https://u.doubochuanmei.com",
      currentSectionId:'',
      currentVideoId:'',
      sectionVideoList:[],
      comment:"",
      isFirst:true,
      innerItems:{},
      code:false,
      codeData:"",
      codeArr:[],
      errData: ''
    };
  },
  created() {
    this.getCourseInfo();
    this.listClass();
    this.getUserInfo()
    this.getPlayerH5URL()
  },
  mounted(){
    
    this.vodDom = document.getElementById('id_video_vod_tengface')
    let myvideo = this.vodDom 
    if(this.$route.query.sectionId){
    this.playvideo(this.vodDom)}
    // window.addEventListener('scroll',this.handleScroll, true)
    // console.log(this.vodDom.duration,'duration');
    window.addEventListener('beforeunload', e => this.historycallback(this.vodDom.currentTime),true)
      let setTimeFlag = 1;//currentTime属性赋值次数
      //当目前的播放位置已更改时触发
      let that=this
      myvideo.addEventListener("timeupdate", function () {
        let tangeAe = sessionStorage.getItem('shipingID')
          if (setTimeFlag == 1) {
              //获取localStorage中记录的currentTime值，并给currentTime属性进行赋值
              if (localStorage.getItem('currentTime_' + tangeAe) != null) {
                if (that.$route.query.view_time&&that.$route.query.video_id==tangeAe) {
                  myvideo.currentTime = that.$route.query.view_time;
                } else{
                  let time = localStorage.getItem('currentTime_' + tangeAe);
                  myvideo.currentTime = time;
                  
                }
                  
              }
              setTimeFlag = 2;
          }
          //获取当前播放位置的秒数，并设置localStorage
          let currentTime = myvideo.currentTime;
          localStorage.setItem('currentTime_' + tangeAe, currentTime)
      });
    if(this.vodDom){
      this.vodDom.addEventListener('play',this.onPlayerPlay,false)
      this.vodDom.addEventListener('pause',this.onPlayerPause,false)
      this.vodDom.addEventListener('ended',this.onPlayerEnded, false);
    }
  },
  watch: {
    optionsa(to) {
      if (to.length > 0) {
        this.$nextTick(() => {  
        //
          if (this.$refs.collapseId0 && this.$refs.collapseId0[0] && this.isFirst &&!this.$route.query.sectionId) {
            this.$refs.collapseId0[0].toggle(true);
          }else{
            // console.log(,'this.$refs.collapseId0[0]');
      // console.log(this.$route.query.sectionId,'document.getElementById');
            if (this.$route.query.sectionId) {
              this.activeNames.push(Number(this.$route.query.sectionId))
              this.scrollTop = document.querySelector(".liveBroadcast");

            this.scrollTop.scrollTop=this.$refs['collapseId'+this.$route.query.sectionId][0].$el.offsetTop
            console.log(this.scrollTop ,'scrollTop');
            console.log(this.$refs['collapseId'+this.$route.query.sectionId][0].$el.offsetTop,'scrollTops');
            //  console.log(this.$refs['collapseId'+this.$route.query.sectionId][0].$el,'this.$refs.$el.offsetTop');
                // console.log(this.$route.query.sectionId,'this.isFirst2');
            }
          }
        })
      }
    }
  },
  destroyed(){
   
      //  console.log(document.getElementById('id_video_vod_tengface'),'kfcl');
  },
  beforeDestroy(){

    window.removeEventListener('beforeunload', e => this.historycallback(this.vodDom.currentTime),true)
    // this.vodDom = document.getElementById('id_video_vod_tengface')
    // console.log(this.$refs.tfPlayer.playerListener(),'测试');
    console.log(this.vodDom.currentTime,'测试');
      this.historycallback(this.vodDom.currentTime)
    
    clearInterval(this.vodTime)
    this.vodDom.removeEventListener('play',this.onPlayerPlay,false)
    this.vodDom.removeEventListener('pause',this.onPlayerPlay,false)
  },
  components: {
    TFPlayer,
  },
  methods: {
    handleScroll(){
        //获取元素高度  this.$refs.init.offsetHeight
        this.scrollTop = document.querySelector(".liveBroadcast");
        // this.scrollTop.scrollTop=1300
        console.log(this.scrollTop.scrollTop);
      },
    historycallback(view_time){
      view_time=view_time.toFixed(0)
      this.innerItems.view_time=view_time
    if (Object.entries(this.innerItems).length>7) {
      this.$api.post("/user/video/callback", this.innerItems)
    }

    },
    historycallbacks(view_time){
      view_time=view_time.toFixed(0)
      this.innerItems.view_time=view_time
      var data = new FormData();
      data.push(this.innerItems)
      Navigator.sendBeacon("https://online.tenghuiwangxiao.cn/user/video/callback",data)
      // this.$api.post(, this.innerItem)
    },
    async sorta(ind){
      // console.log(ind);
      let char_sort=''
      if (!this.sortform[ind]) {
        this.sortform[ind] =0
      }
      this.sortform[ind]++
      if (this.sortform[ind]==1) {
        this.sortimgs[ind]=require('@/assets/images/sorts.png')
        char_sort='asc'

      }else if(this.sortform[ind]==2){
        this.sortimgs[ind]=require('@/assets/images/sortx.png')
        this.sortform[ind] =0
        char_sort='desc'
      }
      console.log(this.sortform,'ind');
      this.getClassCode()
      this.listSections(char_sort)
    },
    async getPlayerH5URL(){
            try {
                let data =  await this.$api.get('https://tengfaceliveuser.tengface.net/web/config/miniConfig.json?a='+ new Date().valueOf())
                if (data && data.data && data.data.wx_h5_url) {
                    this.wx_h5_url = data.data.wx_h5_url;
                }      
            } catch (error) {
                console.error(error)
            } 
            
            try {
              let data2 =  await this.$api.get('https://tengfaceliveuser.tengface.net/web/config/userConfig/'+ this.org_id +'.json?a='+ new Date().valueOf())
                if (data2 && data2.data && data2.data.wx_h5_url) {
                    this.wx_h5_url = data2.data.wx_h5_url;
                }
            } catch (error) {
              console.error(error)
            }
       },
    onPlayerEnded() {
      if (this.sectionVideoList.length > 0) {
        
            let sectionListVideo = this.sectionVideoList.find(item=>{
              return item.sectionId == this.currentSectionId;
            })
            // console.log('4343434334', sectionListVideo)
            if (sectionListVideo) {
                 let vidoes = sectionListVideo.video
                  
                let index = vidoes.findIndex(item1=>{
                    return item1.id == this.currentVideoId;
                    
                 })
                 if (index > -1 && index  < vidoes.length-1 ) {
                     let voideItem = vidoes[index + 1];
                     console.log('4343434334', voideItem)
                     this.liveAutha(this.currentSectionId,voideItem,voideItem.id,2)
                 } 
            }
       }
      // this.currentSectionId
       
       console.log('333333333333')
    },
    onPlayerPlay(){
      this.playStatus = 2
    },
    onPlayerPause(){
      this.playStatus = 3
    },
    guanbiclassget() {
      this.showClass = false;
    },
    goOff() {
      this.$router.go(-1);
    },
    openAll() {
      this.showAll = false;
      this.optionsa = this.optionsaAll;
    },
    classChange(item) {
      console.log(item,'item');
      this.options.forEach((item) => {
        item.select = false;
      });
      item.select = true;
      this.class_id = item.id;
      this.currClassName = item.period;
      this.showClass = false;
      this.historycallback(this.vodDom.currentTime)
      this.getClassCode();
      this.listSections();
    },
    async getUserInfo(){
       const res = await this.$api.post('/user/index/getUserInfo')
       if(res.data.code === 200){
         this.$store.commit("user/setFansId",res.data.data.id)
         this.$store.commit("user/setOrgId",res.data.data.org_id)
       }
    },
    getCourseInfo() {
      // 课程详情
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
      };
      this.$api.post("/stu/course/getCourseInfo", data).then((res) => {
        if (res.data.code === 200) {
          this.name = res.data.data.name;
          this.desc = res.data.data.desc;
          this.cover = res.data.data.cover;
        } else {
          Toast.fail(res.data.message)
        }
      });
    },
    listClass() {
      // 课程班级列表
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
      };
      this.$api.post("/stu/course/listClass", data).then((res) => {
        if (res.data.code === 200) {
          this.options = res.data.data;
          let hasAccessArr = res.data.data.filter((item) => item.access !== 0);
          if (res.data.data && res.data.data.length > 0) {
            if (hasAccessArr.length === 0) {
              let currClassObj = res.data.data[res.data.data.length - 1];
              this.class_id = currClassObj.id;
              this.currClassName = currClassObj.period;
              currClassObj.select = true;
            } else {
              this.class_id = hasAccessArr[0].id;
              this.currClassName = hasAccessArr[0].period;
            }
            if (this.$route.query.class_id) {
              this.class_id = parseInt(this.$route.query.class_id);
            }
            this.options.forEach((item) => {
              if (item.id == this.class_id) {
                item.select = true;
                this.currClassName = item.period;
              } else {
                item.select = false;
              }
            });
            this.getClassCode();
            this.listSections();
          }
        }
      });
    },
    listSections(char_sort='') {
      // 章节列表
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
        class_id: this.class_id,
        page: this.page,
        num: this.num,
        char_sort
      };
      this.$api.post("/stu/course/listSections", data).then((res) => {
        if (res.data.code === 200) {
          this.optionsaAll = res.data.data;
          console.log("sdsdsdsdaafafafasdadss", res.data.data.numStatus);
          if (this.isFirst) {
            // console.log(7777);
            let chapterindex = 0
            let chapterJie = null
            try {
              this.optionsaAll.forEach((item, index) => {
                item.chapters.forEach((item2) => {
                  if(this.codeArr.indexOf(item2.id) != -1 ){
                        item2.show = 1
                      }else{
                        item2.show = 2
                      }
                  if (item2.status == 2) {
                    chapterindex = index
                    chapterJie = item2.id
                    this.showAll = false
                    this.optionsa = res.data.data
                    throw new Error(chapterindex);
                  }
                })
              })
              
            } catch (e) {
              console.log(88888);

              // if (e.message != "End") throw e;
              this.$nextTick(() => {
                console.log(this.isFirst,'this.isFirst1');
                console.log(this.$refs['collapseId' + chapterindex][0],'this.$refs.collapseId2[0]');
                console.log(chapterindex,'chapterindex');

                this.$refs['collapseId' + chapterindex][0].toggle(true);
               
                // this.$refs.collapseId1[0].toggle(true);
                // this.isFirst = false;
                 
                // console.log(!!chapterJie,'!!chapterJie');
                if (this.isFirst && !!chapterJie) {
                                this.isFirst = false;
              console.log(this.isFirst,'this.isFirst4');
                  // console.log(this.isFirst,'this.isFirst3');
                  setTimeout(() => {
                    
                    console.log(12345,'aaa');
                    const parent = this.$refs.course_chapter0
                    // console.log(54321,'bbb');
                    // console.log(chapterJie,'chapterJie');
                    // console.log(this.$refs["chapterJie" + chapterJie],'this.$refs["chapterJie" + chapterJie]11');
                    // console.log(this.$refs["chapterJie" + chapterJie][0],'this.$refs["chapterJie" + chapterJie]22');
                    // console.log(this.$refs["chapterJie" + chapterJie][0].offsetTop,'this.$refs["chapterJie" + chapterJie]33')

                    const position = this.$refs["chapterJie" + chapterJie][0].offsetTop

                    // console.log(parent,'parent');
                    // console.log(position,'position');
                    
                    let lastTop  = chapterindex*44 + position
                    // console.log(lastTop,'aaaaaaaaaaaaaaappp');
                    parent.scrollTo({
                      top: lastTop,
                      behavior: 'smooth'
                    })


                    
                  }, 1000);
                }
              })
            }
            
            if (chapterJie === null) {
              console.log(2333333);
              if (this.optionsaAll.length > 5) {
                this.showAll = this.$route.query.sectionId?false:true
                this.optionsa =this.$route.query.sectionId?this.optionsaAll: this.optionsaAll.slice(0, 5)
              } else {
                this.showAll = false
                this.optionsa = res.data.data
              }
            }

          } else {
            if (this.optionsaAll.length > 5) {
              this.showAll = this.$route.query.sectionId?false:true
              this.optionsa =this.$route.query.sectionId?this.optionsaAll: this.optionsaAll.slice(0, 5)
            } else {
              this.showAll = false
              this.optionsa = res.data.data
            }

          }

          this.classInfo();
          this.tancalsslist = res.data.data
          const list = [];
             
             this.tancalsslist.forEach(item=>{
              let vodes = []
              item.chapters.forEach(item1=>{
                if(this.codeArr.indexOf(item1.id) != -1 ){
                  item1.show = 1
                }else{
                  item1.show = 2
                }
                if(item1.videos.length !== 0){
                   vodes = vodes.concat(item1.videos);
                }
               
              })
               list.push({
                    sectionId:item.sectionId,
                    video:vodes,
                 })
              //  console.log(item.chapters)
             })
             this.sectionVideoList = list;
             console.log('eeeeeeeeeeeee', list)
            
        }
      });
    },
    setup() {
      console.log(555);
      const activeNames = ref(["0"]);
      return { activeNames };
    },
    liveAuth(item1,show) {
      // 直播鉴权
      console.log('111',item1)
      const data = {
        course_id: this.$route.query.setid,
        class_id: this.class_id,
        chapter_id: item1.id,
      };
      this.$api.post("/stu/course/liveAuth", data).then((res) => {
        if (res.data.code === 200) {
          if(item1.type == 4){
            this.fakeLiveJump(item1, res.data)
          }else{
            this.liveAddress(item1, res.data);
          }
        } else {
          if(show == 1){
            this.errData = ''
            this.code = true
          }else{
            Toast.fail(res.data.message);
          }
        }
      });
    },

    transformation(duration) {
      let g = duration;
      let m = parseInt((duration / 60) % 60);
      let s = duration % 60;
      if (s <= 9) {
        s = "0" + s;
      }
      if (m <= 9) {
        m = "0" + m;
      }
      if (g >= 3600) {
        g = g / 3600;
        g = parseInt(g);
        g = "0" + g + ":";
      } else {
        g = "";
      }
      return g + m + ":" + s;
    },
    liveAutha(sectionId, item, video_id,video_item,items,show) {
      this.innerItems={
        chapter_id:video_item.chapter_id,
        course_id:item.course_id,
        class_id:item.class_id,
        video_id:video_item.id,
        file_id:video_item.file_id,
        name:video_item.name,
        duration:video_item.duration,
        sectionId:items.sectionId
      }
      console.log(items,'this.innerItems');
      // if (this.acterss) {
      //   localStorage.removeItem('currentTime_' + this.acterss)
      // }
      this.acterss = video_id
      this.currentSectionId = sectionId;
      this.currentVideoId = video_id

      // 录播鉴权
      console.log('00000000000sectionId===',sectionId ,item)
      const data = {
        course_id: this.$route.query.setid,
        class_id: this.class_id,
        chapter_id: item.chapter_id || item.id,
        video_id:video_id,
      };
      this.$api.post("/stu/course/videoAuth", data).then((res) => {
        if (res.data.code === 200) {
          sessionStorage.setItem('shipingID', video_id)
          const vodData = {
            mp4Url: res.data.data.video.mp4Url,
          };
          this.fileId = res.data.data.video.file_id
          this.VODData = vodData;
          this.$refs.tfPlayer.load();
          this.$refs.tfPlayer.play();
          setTimeout(() => {
             this.$refs.tfPlayer.play();
          },  2000);
          this.startVodTime()
        } else {
          if(show == 1){
            this.errData = ''
            this.code = true
          }else{
            Toast.fail(res.data.message);
          }
        }
      });
    },
    playvideo(vodDom){
     
      this.showAll=false
      const data = {
        course_id: this.$route.query.setid,
        class_id: this.$route.query.class_id,
        chapter_id: this.$route.query.chapter_id,
        video_id:this.$route.query.video_id,
      
      };
      this.$api.post("/stu/course/videoAuth", data).then((res) => {
        if (res.data.code === 200) {
          // sessionStorage.setItem('shipingID', video_id)
          const vodData = {
            mp4Url: res.data.data.video.mp4Url,
          };
          this.fileId = res.data.data.video.file_id
          this.VODData = vodData;
          this.$refs.tfPlayer.load();
          this.$refs.tfPlayer.play();
          setTimeout(() => {
             this.$refs.tfPlayer.play();
             this.vodDomduration=vodDom.duration.toFixed(0)
             this.innerItems={
                chapter_id:this.$route.query.chapter_id,
                course_id:this.$route.query.setid,
                class_id:this.$route.query.class_id,
                video_id:this.$route.query.video_id,
                file_id:this.fileId,
                name:this.$route.query.name,
                duration:this.vodDomduration,
                sectionId:this.$route.query.sectionId
              }
              console.log(vodDom.duration,'duration');
          },  2000);
          this.startVodTime()
          console.log(this.VODData,'this.VODData');
        } else {
          Toast.fail(res.data.message);
        }
      });
    },
    startVodTime () {
      clearInterval(this.vodTime)
      this.vodTime = setInterval(() => {
        let token = localStorage.getItem('token')
        if (!token) {
          this.vodTime && clearInterval(this.vodTime)
        }
        if (this.playStatus == 2 && token) {
          const progress = parseInt(this.vodDom.currentTime)
          const data ={
            file_id:this.fileId,
            fans_id:this.fans_id,
            uid:this.org_id,
            progress
          }
          this.$api.post("https://user-data.tenghuiwangxiao.cn/app/index/videoHeart", data).then(res=>{
            console.log(res)
          })
        }
      }, 5000)
    },
    fakeLiveJump(item1,item) {
      const liveUrl = `${this.wx_h5_url}/f/?ds_id=${item1.ds_id}&tid=${
        item1.room_id
      }&token=${
        item.data.liveToken
      }&other=1&shool_token=${localStorage.getItem('token')}`

      console.log("fakeLiveJump",liveUrl);

      location.href = liveUrl
    },
    liveAddress(item1, item) {
      console.log("item1",item1)
      // 查询直播地址
      const liveUrl = `${this.wx_h5_url}/?ds_id=${
        item1.ds_id
      }&token=${
        item.data.liveToken
      }&other=1&shool_token=${localStorage.getItem('token')}`
      console.log("liveAddress",liveUrl);
      location.href = liveUrl;

      // const data = {
      //   chapter_id: id,
      // };
      // this.$api.post("/stu/course/liveAddress", data).then((res) => {
      //   if (res.data.code === 200) {
      //     const url = `${res.data.data.live_url}&token=${item.data.liveToken}&shool_token=${localStorage.getItem('token')}&other=1`;
      //     location.href = url;
      //   }
      // });
    },
    classInfo() {
      // 查询班级信息
      const data = {
        org_id: this.org_id,
        classId: this.class_id
      }
       this.$api
        .post('/stu/course/classInfo', data)
        .then((res) => {
          if (res.data.code === 200) {
            if(res.data.data.chapter){
              this.start_timeList = res.data.data.chapter.start_time
            }
            this.numStatus = res.data.data.numStatus
            this.studyNum = res.data.data.studyNum
            this.comment  = res.data.data.comment
          }
        })
    },
    // 获取当前课程有兑换码章节
    getClassCode() {
      const data = {
        org_id: this.org_id,
        class_id: this.class_id,
        course_id: this.$route.query.setid,      
      }
      this.$api.post('/stu/course/getClassCode', data).then((res) => {
        if (res.data.code === 200) {
          const cdarr = res.data.data;
          this.codeArr = Object.values(cdarr);
        }
      })
    },

    // 兑换码兑换课程
    useCdk() {
      const data = {
        org_id: this.org_id,
        code : this.codeData,
        class_id: this.class_id,
        course_id: this.$route.query.setid,
      }
      this.$api.post('/stu/course/useCdk', data).then((res) => {
        console.log(res.data.code)
        if (res.data.code === 200) {
            this.code = false
            this.codeData = ''
          } else if (res.data.code === 400) {
            this.errData = res.data.message
          }
      })
    },
  }
}
</script>
<style scoped>
 .yuyuyu >>> .el-dialog {
  border-radius: 20px;
 }
 .kechengduihuan {
  font-size: 20px;
  font-family: PingFangSC-Medium;
  text-align: center;
  color: #202020;
  margin-bottom: 5px;
 }
 .huoqulist {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #999999;
  margin-top: 1px;
 }
 .yuyuyu >>> .el-input__inner {
  background: #f5f5f5;
border-radius: 4px;
width: 244px;
height: 36px;
border: none;
 }
 .lijiduan {
  width: 140px;
  height: 36px;
  background: linear-gradient(95deg,#78d4b6 4%, #0bb67e 95%);
  border-radius: 18px;
  border: none;
  color: #fff;
 }
 .keduihuan{
  width: 38px;
  height: 16px;
  background: #ffece7;
  border-radius: 2px;
  font-size: 10px;
  font-family: PingFangSC-Regular;
  text-align: left;
  color: #f33f27;
  line-height: 14px;
  margin-left: 5px;
  padding: 2px;
 }
 .codeshuru{
  width: 244px;
  height: 36px;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 0 10px;
 }
 .yiguoqilist {
  font-size: 10px;
  font-family: PingFangSC-Regular;
  text-align: left;
  color: #ff5227;
  line-height: 20px;
  padding-left: 10px;
  margin: 0;
 }
 .yuyuyu >>> .el-dialog--center .el-dialog__body {
  padding: 25px 25px 0px;
 }
</style>
<style scoped>
.sortimg{
  width: 0.5rem;
  height: 0.5rem;
}
.sort{
  font-size: 0.375rem;
  width: 100%;
  display: flex;
  justify-content: right;
}
.activest{
  color: #10A675;
}
.stbx{
  width: 1.5rem;
  height: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid #B7B7B7 1px;
}
.stbx1{
  color: #10A675;
  background: #E6F6F0;
  border: solid #10A675 1px;
}
.tfPlayer >>> .playBtn{
  width:12% !important;
}
.contentBox >>> .van-cell--clickable {
  background: #f6f6f6;
}
.liveBroadcast .headerBox {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
}

.liveBroadcast .contentBox {
  padding-top: 7.38rem;
}

.dsanbf >>> .el-input__inner {
  border: none;
  width: 185px;
}
.banji {
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  float: right;
  margin-top: 0.48rem;
  margin-right: 0.43rem;
  height: 24px;
  line-height: 24px;
  border: #2eb186 1px solid;
  border-radius: 12px;
  padding: 0 8px;
  color: #2eb186;
}
/* 头部 */
.diyi {
  width: 100%;
  height: 5.33rem;
  overflow: hidden;
  background: #fff;
}

.diyi .courseCover {
  z-index: 3000;
  width: 100%;
  height: 100%;
}

.fanhui {
	width: 25px;
    height: 25px;
    opacity: 0.5;
    background: #000000;
    border-radius: 50%;
    text-align: center;
    top: 10px;
    left: 10px;
    position: absolute;
	z-index: 9999;
}
.fanhuiyi {
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background: #000000;
  border-radius: 50%;
  text-align: center;
  margin-top: 13px;
}
.fanhuier {
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background: #000000;
  border-radius: 50%;
  text-align: center;
  margin-top: 13px;
  margin-left: 18px;
}
.mianfei {
  width: 109px;
  height: 40px;
  opacity: 0.5;
  background: #000000;
  border-radius: 20px;
  clear: both;
  margin: 0 auto;
  /* margin-top: 88px; */
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}

/* 第二部分 */
.debf {
  width: 100%;
  /* height: 77px; */
  background: #fff;
  overflow: hidden;
}
.liveBroadcast {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  height: 100%;
}
.liveBroadcast .fafa {
  font-size: 0.48rem;
  line-height: 0.64rem;
  font-weight: 600;
  color: #101010;
  margin-top: 0.27rem;
  margin-left: 0.43rem;
  margin-bottom: 0px;
}
.liveBroadcast .zhibosj {
  margin: 0px;
  margin-top: 0.29rem;
  margin-left: 0.43rem;
  font-size: 0.32rem;
  line-height: 0.43rem;
  font-weight: 400;
  color: #888888;
}

.liveBroadcast .mytestping {
  margin: 0px;
  margin-top: 5px;
  padding: 0 0.43rem;
  font-size: 0.32rem;
  line-height: 0.43rem;
  font-weight: 400;
  color: #888888;
}

.liveBroadcast .rightStudy {
  float: right;
  margin-right: 0.43rem;
}

.liveBroadcast .rightStudy img {
  vertical-align: -0.05rem;
}

.liveBroadcast .qishi {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #10a675;
  margin-left: 0.13rem;
}

.liveBroadcast .lineBox {
  width: 100%;
  height: 0.27rem;
  background-color: #f6f6f6;
}

/* 第三部分 */
.liveBroadcast .dsanbf {
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  padding-bottom: 0.53rem;
}
.liveBroadcast .mulu {
  font-size: 0.48rem;
  line-height: 0.59rem;
  font-weight: 600;
  color: #101010;
  margin-top: 0.43rem;
  margin-left: 0.43rem;
  margin-bottom: 0px;
}
.aglook{
  padding: 2px 5px;
  border-radius: 5px;
  color: #528dfb;
  font-size: 12px;
}
.liveBroadcast .courseItemView {
  overflow: hidden;
  margin-top: 0.53rem;
}

.liveBroadcast .linyi {
  font-size: 0.37rem;
  color: #20242f;
  margin-left: -0.27rem;
}

.liveBroadcast .videoItem {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-left: 0.63rem;
  margin-top: 0.43rem;
}

.liveBroadcast .videoItem .playBtn {
  /* width: 0.37rem; */
width:  0.37rem;
height: 0.37rem;
  vertical-align: -0.05rem;
}

.liveBroadcast .videoItem .time {
  min-width: 80px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.32rem;
  color: #888888;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 0.53rem;
  margin-top: 0.1rem;
  margin-bottom: 0px; */
}

.huifanga {
  background: #d9e8fe;
  border-radius: 2px;
  padding: 2px 3px;
  font-size: 0.32rem;
  font-weight: 400;
  color: #3f8ef8;
  margin-left: 0.45rem;
}
.huifangb {
  background: #e6f6f0;
  border-radius: 2px;
  padding: 2px 3px;
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 0.45rem;
}
.chushi {
  font-size: 0.37rem;
  font-weight: 500;
  color: #10a675;
  margin-left: 6px;
}
.chushia {
  font-size: 0.37rem;
  font-weight: 500;
  color: #101010;
  margin-left: 0.21rem;
}
.chushialist {
  font-size: 0.37rem;
  font-weight: 500;
  color: #101010;
  margin-left: 0.43rem;
}
.shijianb {
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 49px;
}
.shijianc {
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 10px;
}
.liveBroadcast .shijiand {
  font-size: 0.32rem;
  font-weight: 400;
  color: #888888;
  margin-left: 1.28rem;
}
.shijianf {
  font-size: 0.32rem;
  font-weight: 400;
  color: #888888;
  margin-left: 10px;
}
.gengduo {
  font-size: 0.37rem;
  font-weight: 400;
  color: #10a675;
}
/* 第四部分 */
.dsibf {
  width: 100%;
  background: #fff;
  overflow: hidden;
}

.meiyou {
  width: 100%;
  height: 37px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #888888;
}
.dibu {
  width: 100%;
  height: 58px;
  background: #fefffe;
  box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.05);
}
.liji {
  width: 260px;
  height: 40px;
  background: linear-gradient(90deg, #fdb56b, #fc6d2b);
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 9px;
  margin-left: 31px;
}
.active {
  color: #10a675;
}
.xuanban {
  font-size: 0.37rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #000000;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 33px;
}
.luse {
  font-size: 0.43rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  /* color: #10a675; */
  margin-left: 20px;
  margin-top: 26px;
}
.xuanzhong {
  float: right;
  margin-top: -36px;
  margin-right: 25px;
}
.xuanzhong img {
  width: 0.53rem;
  height: 0.53rem;
}
.jirzb {
  margin-right: -0.33rem;
  float: right;
  background: linear-gradient(95deg, #78d4b6 4%, #0bb67e 95%);
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #fefffe;
  padding: 5px 11px;
  border-radius: 14px;
  margin-top: -20px;
}
.jirzblist {
  float: right;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #888888;
  margin-top: -20px;
}
.guanbiclass {
  position: absolute;
  width: 0.37rem;
  height: 0.37rem;
  top: 0.61rem;
  right: 0.4rem;
}
.zhangjielist {
  font-size: 0.43rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #20242f;
  margin-top: 0.27rem;
}
</style>

<style>
.kecheng_desc img {
  width: 100%;
}
</style>
