<template>
  <div style="min-width: 350px">
    <header>
      <div @click="goOff()" class="fanhui center">
        <a href="javascript:void(0);"></a>
      </div>
      <div class="title">
        <span>登录</span>
         <router-link
            :to="{ path: 'zhucezh', query: { setid: 1231231 } }"
          >
        <p>还好没有账号？<a href="javascript:void(0);">去注册</a></p>
        </router-link>
      </div>
      <!-- 手机号码 验证码 登录键 -->
      <div class="shuru center">
        <div class="number">
          <input
            style="width: 100%"
            v-model="account"
            class="phone"
            type="text"
            placeholder="请输入手机号码"
          />
        </div>
        <!-- <div style="margin-top: 20px;" class="number">
                 <input v-model="password" style="width: 280px;" class="password" type="password" placeholder="请输入手机号码">
             </div> -->
        <div class="yanzheng">
          <input style="width: 100%" v-model="password" type="password" placeholder="请输入密码" />
        </div>
      </div>
    </header>
    <div class="denglu center">
      <button @click="login()">登录</button>
    </div>
     <!-- <router-link
            :to="{ path: 'denglu', query: { setid: 1231231 } }"
          > -->
        <span class="sjdl" @click="toCodeLoginPage">验证码登录</span>
    <!-- </router-link> -->
    <router-link
            :to="{ path: 'zhuce', query: { setid: 1231231 } }"
          >
    <span class="wjmm">忘记密码？</span>
    </router-link>
    <!-- 已阅读协议 -->
    <div class="xieyi">
      <img src="../../assets/img/ico_xuanzhong.png" alt="" />
      <span>
        已阅读并同意<a href="javascript:void(0);" @click="$router.push({path: '/privacy'})">《服务协议》</a>和<a href="javascript:void(0);" @click="$router.push({path: '/agreement'})">《隐私协议》</a>
      </span>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'

Vue.use(Toast)

export default {
   computed:{
		...mapGetters({
			org_id:'user/getOrgId'
		})
	},
  data () {
    return {
      account: '',
      password: ''
    }
  },
  created () {
  },
  methods: {
    goOff () {
      // this.$router.push({
      //   path: '/'
      // })
      // this.toPage()
      const { redirectUrl } = this.$route.query
      if(redirectUrl && redirectUrl.includes('zhibo')) {
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    },
    checkOrgIdIsChunzai(callBack){
      if (this.org_id) {
        callBack && callBack(this.org_id) 
      }else{
          this.$api.post('/common/index/getOrgInfo', {
          domain: window.location.host
        }).then((res)=>{
          if(res.data.code == 200){
            this.$store.commit("user/setOrgId",res.data.data.uid)
            localStorage.setItem('tengface_org_id',res.data.data.uid)
          callBack && callBack(res.data.data.uid) 	
          }
        }).catch(()=>{
          callBack && callBack() 
        });   
      }
    },
    // 登录
    login () {
      if (this.account === '' || this.password === '') {
        Toast.fail('账号、密码不能为空')
      }

      this.checkOrgIdIsChunzai((org_id)=>{
        const data = {
        account: this.account,
        password: this.password,
        org_id: org_id
      }
       this.$api.post('/user/index/login', data)
        .then(res => {
          if (res.data.code === 200) {
            localStorage.setItem('token', res.data.data.token)
            this.$store.commit("user/setFansId",res.data.data.fansId)
            localStorage.setItem('tengface_fans_id', res.data.data.fansId)
            Toast.success('登录成功')
            // this.$router.push({
            //   path: '/'
            // })
            this.toPage()
          } else {
            Toast.fail('账号密码错误或为空')
          }
        })
      });
    },
    toPage() {
      const { redirectUrl } = this.$route.query
      console.log('redirectUrl: ', this.$route);
      if(redirectUrl) {
        // window.location.href = redirectUrl
        window.location.replace(redirectUrl)
      } else {
        this.$router.replace({
          path: '/'
        })
      }
    },
    toCodeLoginPage() {
      const { redirectUrl } = this.$route.query
      this.$router.replace({ path: '/denglu', query: { redirectUrl }  })
    }
  }
}
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
body {
  width: 100%;
  height: 768px;
  font-family: PingFangSC, PingFangSC-Semibold;
}
.center {
  margin: 0 auto;
}
input {
  border: 0;
}
a {
  text-decoration: none;
}
/* 头部 */
.sjdl{
font-size: 14px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
color: #10a675;
margin-left: 30px;
}
.wjmm{
  font-size: 14px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
float: right;
color: #808695;
margin-right: 30px;
}
header {
  height: 293px;
  background: url(../../assets/img/denglu_bg.png);
  background-size: 100% 100%;
}
header .fanhui {
  height: 44px;
}
header .fanhui a {
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/ico_fanhui.png) no-repeat center;
  position: relative;
  top: 10px;
  left: 16px;
}
header .title {
  margin-top: 35px;
  margin-left: 30px;
}
header .title span {
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #303133;
}
header .title p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #888888;
}
header .title p a {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #10a675;
}
.shuru {
  width: 84%;
  margin-top: 24px;
}
.shuru .number {
  overflow: hidden;
  height: 44px;
  background: #f6f6f6;
  border-radius: 22px;
}
.shuru .number span {
  display: block;
  float: left;
  margin-left: 6px;
  width: 46px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #101010;
}
.password input {
  float: left;
  height: 44px;
  background: #f6f6f6;
  width: 380px !important;
}
.shuru input {
  float: left;
  height: 44px;
  background: #f6f6f6;
  padding: 0 20px;
  border-radius: 25px;
}

.shuru .yanzheng {
  overflow: hidden;
  margin-top: 20px;
  /* width: 315px; */
  height: 44px;
  background: #f6f6f6;
  border-radius: 22px;
}
.shuru .yanzheng p {
  display: block;
  float: right;
  height: 44px;
  margin-right: 20px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #10a675;
}
.denglu {
  width: 84%;
}
.denglu button {
  margin-bottom: 20px;
  display: block;
  width: 320px;
  margin-top: 25px;
  height: 44px;
  background: linear-gradient(95deg, #78d4b6 4%, #0bb67e 95%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  line-height: 44px;
  border: none;
}
.xieyi {
  height: 20px;
  line-height: 20px;
  width: 249px;
  margin-left: 30px;
  margin-top: 12px;
}
.xieyi img {
  position: relative;
  top: 2px;
}
.xieyi span {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #303133;
}
.xieyi span a {
  color: #10a675;
}
</style>
