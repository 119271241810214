<template>
    <div style="min-width: 340px;">
        <header class="center">
         <div class="fanhui center">
            <a href="javascript:void(0);" @click="goOff()"></a>
         </div>
         <div class="title">
             <span>修改密码</span>
         </div>
         <!-- 手机号码 验证码 登录键 -->
         <div class="shuru center">
             <div class="number">
                 <span class="spanliss"  @click="showPopup = true">
             +{{AreacodeList}}
             <van-icon
            v-if="showPopup == false"
            style="position: absolute;top: 179px;left: 70px;"
            name="arrow"
          /><van-icon
            v-else-if="showPopup == true"
            style="position: absolute;top: 179px;left: 70px;"
            name="arrow-down"
          />
           </span>
          <van-popup v-model="showPopup" position="bottom" :style="{ height: '100%' , background: '#f6f6f6' }">
            <div style="text-align: center;background: #fff;height: 48px;overflow: hidden;"><span class="shuruss" @click="showClass()" style="position: absolute;top: 14px;left: 18px;">取消</span><p style="margin-top: 13px;">选择国家地区</p></div>
            <div style="background: #fff;margin-top: 10px;overflow: hidden;">
                <p  @click="classChange(item)" v-for="(item, index) in Areacode" :key="index" style="padding: 10px 0;border-bottom: 1px solid #f6f6f6;"><span style="margin-left: 20px;">{{item.nameZh}}</span> <span style="float: right;margin-right: 50px;">+{{item.phonecode}}</span></p>
            </div>
          </van-popup>
                 <input type="text" v-model="phone" placeholder="请输入手机号码">
             </div>
             <div class="yanzheng">
                <input type="text" v-model="code" placeholder="请输入验证码">
                <a href="javascript:void(0);" v-show="show" @click="yanzheng ()">获取验证码</a>
                 <span
            v-show="!show"
            style="margin-left: 26px; line-height: 46px;font-size: 12px;color: #888888;"
            class="count"
            >重新获取 {{ count }} s</span
          >
            </div>
             <div class="yanzheng">
                <input type="password" v-model="password" placeholder="设置新密码6-15位 数字+字母密码">
            </div>
         </div>
     </header>
     <div class="zhuce center">
        <a href="javascript:void(0);" @click="lijizhuce()">立即修改</a>
    </div>
    <!-- 已阅读协议 -->
    <!-- <div class="xieyi center">
        <div class=" xieyi1 center">
        <img src="../../assets/img/ico_xuanzhong.png" alt="">
        <span>
            已阅读并同意<a href="javascript:void(0);">《服务协议》</a>和<a href="javascript:void(0);">《隐私协议》</a>
        </span>
    </div>
    </div> -->
    </div>
</template>
<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { mapGetters } from 'vuex'

Vue.use(Toast)
export default {
  computed:{
		...mapGetters({
			org_id:'user/getOrgId'
		})
	},
  data () {
    return {
      AreacodeList: '86',
      Areacode: '',
      showPopup: false,
      password: '',
      code: '',
      phone: '',
      count: '',
      show: true,
    }
  },
  created () {
    this.areaCodes()
  },
  methods: {
     showClass () {
      this.showPopup = false
    },
    classChange (item) {
      this.AreacodeList = item.phonecode
      this.showPopup = false
      this.areaCodes()
    },
    goOff () {
      this.$router.go(-1)
    },
    lijizhuce () {
      if (this.password === '' || this.code === '' || this.phone === '') {
        Toast.fail('手机号、验证码、密码不能为空')
      }

      this.checkOrgIdIsChunzai((org_id)=>{
        const data = {
          phone: this.phone,
          area: this.AreacodeList,
          code: this.code,
          password: this.password,
          org_id: org_id
        }
        this.$api.post('/user/index/updatePwd', data)
          .then(res => {
            if (res.data.code === 200) {
              Toast.success('修改成功')
              this.$router.push({
                path: '/zhanghaomimadl'
              })
            } else {
              Toast.fail('修改失败')
            }
          }) 
      })
      
    },
    checkOrgIdIsChunzai(callBack){
      if (this.org_id) {
        callBack && callBack(this.org_id) 
      }else{
          this.$api.post('/common/index/getOrgInfo', {
          domain: window.location.host
        }).then((res)=>{
          if(res.data.code == 200){
            this.$store.commit("user/setOrgId",res.data.data.uid)
            localStorage.setItem('tengface_org_id',res.data.data.uid)
          callBack && callBack(res.data.data.uid) 	
          }
        }).catch(()=>{
          callBack && callBack() 
        });   
      }
    },
    // 获取验证码
    yanzheng () {

      this.checkOrgIdIsChunzai((org_id)=>{
        const data = {
        phone: this.phone,
        area: this.AreacodeList,
        org_id: org_id
      }
       this.$api
        .post(
          '/common/index/sendSms',
          data
        )
        .then((res) => {
          if (res.data.code === 200) {
            Toast.success('获取成功')
          }
          if (res.data.code === 200) {
            const TIME_COUNT = 60
            if (!this.timer) {
              this.count = TIME_COUNT
              this.show = false
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--
                } else {
                  this.show = true
                  clearInterval(this.timer)
                  this.timer = null
                }
              }, 1000)
            }
          } else {
            Toast.fail('获取失败')
          }
        })
      });
      
    },
    // 地区码获取
    areaCodes () {
      this.$api.post('/common/index/areaCodes')
        .then(res => {
          if (res.data.code === 200) {
            this.Areacode = res.data.data
          }
        })
    }
  }
}
</script>
<style scoped>
.shuru input{
  background: #f6f6f6;
}
    * {
    padding: 0;
    margin: 0;
}
body {
    width: 100%;
    height: 768px;
    font-family: PingFangSC, PingFangSC-Semibold;
}
.center {
    margin: 0 auto;
}
input {
    border: 0;
}
a {
    text-decoration: none;
}
/* 头部 */
header {
    height: 293px;
    width: 100%;
    background: url(../../assets/img/denglu_bg.png) no-repeat;
    background-size: 100% 100%;
}
header .fanhui {
    height: 44px;
}
header .fanhui a {
    display: block;
    width: 20px;
    height: 20px;
    background: url(../../assets/img/ico_fanhui.png) no-repeat center;
    position: relative;
    top: 10px;
    left: 16px;
}
header .title {
    margin-top: 35px;
    margin-left: 30px;
}
header .title span {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #303133;
}
header .title p {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #888888;
}
header .title p a {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #10a675;
}
.shuru {
    width: 84%;
    margin-top: 54px;
}
.shuru .number {
    height: 44px;
    background: #f6f6f6;
    border-radius: 22px;
}
.spanliss {
    display: block;
    float: left;
    margin-left: 6px;
    width: 46px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #101010;
}
.shuru input {
    float: left;
    height: 44px;
    background: #f6f6f6;
}
.shuru .number input {
    width: 67%;
}
.shuru .yanzheng {
    margin-top: 20px;
    height: 44px;
    background: #f6f6f6;
    border-radius: 22px;
}
.shuru .yanzheng input {
    width: 57%;
    margin-left: 16px;
}
.shuru .yanzheng a {
    display: block;
    float: right;
    margin-right: 20px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #10a675;
}
.zhuce {
    width: 84%;
}
.zhuce a {
    margin: 0 auto;
    display: block;
    margin-top: 64px;
    height: 44px;
    background: linear-gradient(95deg,#78d4b6 4%, #0bb67e 95%);
    border-radius: 22px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 44px;
}
.xieyi {
    height: 20px;
    line-height: 20px;
    margin-top: 12px;
}
.xieyi img {
    position: relative;
    top: 2px
}
.xieyi span {
font-size: 12px;
font-weight: 400;
text-align: left;
color: #303133;
}
.xieyi span a {
    color: #10a675;
}
.xieyi1 {
    width: 84%;
}
</style>
