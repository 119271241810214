import Vue from 'vue'
import VConsole from 'vconsole'
import App from './App.vue'
import router from './router'
import store from './store/store'
import api from '@/api/onlineSchool'
import Vant from 'vant'
import common from './utils/common'
import 'vant/lib/index.css'
import 'lib-flexible'
// import '@/utils/auto-import'
import './router/permission'

Vue.use(Vant)
Vue.prototype.$api = api
Vue.config.productionTip = false

// 正式环境根据设备类型 跳转终端
if(process.env.NODE_ENV == 'production' && !location.hash.includes('checkMobile')){
	common.checkEquipment('h5');
}

if(process.env.NODE_ENV == 'development') { 
  new VConsole()
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
