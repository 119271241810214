<template>
    <div style="min-width: 340px;">
          <!-- 标题 -->
    <div class="title">消息</div>
    <!-- 消息 -->
    <div class="message">
        <ul>
            <li>
                  <router-link :to="{path:'liaotian',query:{setid:1231231}}">
                <a href="#">
                    <img src="../../assets/img/ico_xitongtongzhi.png" alt="">
                    <div class="message-one">
                        <span>系统通知</span>
                        <p>有一节课在三天内开课</p>
                    </div>
                    <div class="message-two">12:00</div>
                </a>
                </router-link>
            </li>
            <li>
                <a href="#">
                    <img src="../../assets/img/ico_xitongtongzhi.png" alt="">
                    <div class="message-one">
                        <span>系统通知</span>
                        <p>有一节课在三天内开课</p>
                    </div>
                    <div class="message-two">12:00</div>
                </a>
            </li>
            <li>
                <a href="#">
                    <img src="../../assets/img/ico_xitongtongzhi.png" alt="">
                    <div class="message-one">
                        <span>系统通知</span>
                        <p>有一节课在三天内开课</p>
                    </div>
                    <div class="message-two">12:00</div>
                </a>
            </li>
        </ul>
    </div>
    <!-- 尾部 -->
    <div style="margin-top: 48px;">
      <van-tabbar active-color="#10A675" v-model="active">
        <van-tabbar-item icon="home-o" :to="{path:'/'}">首页</van-tabbar-item>
        <van-tabbar-item icon="orders-o" :to="{path:'myStudy',query:{setid:123123}}">学习</van-tabbar-item>
        <van-tabbar-item icon="chat-o" :to="{path:'xiaoxi',query:{setid:123123}}">消息</van-tabbar-item>
        <van-tabbar-item icon="smile-o" :to="{path:'my',query:{setid:123123}}">我的 </van-tabbar-item>
      </van-tabbar>
    </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      active: 2
    }
  }
}
</script>
<style scoped>
  * {
    padding: 0;
    margin: 0;
}
body {
    width: 100%;
}
a {
    text-decoration: none;
}
li {
    list-style: none;
}
/* 标题 */
.title {
    height: 44px;
    margin: 0 auto;
    text-align: center;
    line-height: 44px;
    font-size: 17px;
    font-weight: 600;
    color: #181818;
}
/* 消息 */
.message {
    margin: 0 auto;
    height: 675px;
}
.message ul li {
    display: block;
    float: left;
    width: 95%;
    margin: 16px 0;
    margin-left: 16px;
}
.message ul li img {
    float: left;
}
.message ul li a {
    display: block;
}
.message ul li a .message-one {
    width: 142px;
    float: left;
    margin-left: 16px;
}
.message ul li a .message-one span {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #181818;
    line-height: 22px;
}
.message ul li a .message-one p {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #888888;
}
.message ul li a .message-two {
    float: right;
    margin-right: 20px;
    margin-top: 2px;
    width: 30px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #888888;
    line-height: 17px;
}
/* 尾部 */
.bottom {
    height: 49px;
    margin: 0 auto;
}
.bottom ul li {
    float: left;
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 7px;
}
.bottom ul li p {
    height: 14px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #181818;
    line-height: 14px;
}
</style>
