const state = {
	org_id: localStorage.getItem('tengface_org_id') || "",
  fans_id:localStorage.getItem('tengface_fans_id') || "",
  mofang_url: localStorage.getItem('tengface_mofang_url') || ""
}

const getters = {
    getOrgId(state){
      return state.org_id
    },
    getFansId(state){
		return state.fans_id
	},
  getMoFangUrl(state) {
    return state.mofang_url
  }
}

const actions = {
   
}

const mutations = {
   setOrgId(state, data) {
     state.org_id = data
   },
   setFansId(state, data) {
     state.fans_id = data
   },
   setMoFangUrl(state, data) {
    state.mofang_url = data
  }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
