<template>
	<div
		class="option flex"
		@click="handleClick"
		:class="{
			act: userSelect.includes(option.key),
			did: userSelect.includes(option.key) && wrongAnswer(option.key) && pageType == 'check'
		}"
	>
		<span style="color:#888888" v-if="question.type !== 3">{{ option.key }}</span>
		<div class="option-cont flex flex-column flex-1">
			<span>{{ option.value }}</span>
			<!-- <img :class="{ mt20: option.content }" v-if="option.img" :src="option.img" mode=""/> -->
		</div>
    <template>
			<img
				v-if="userSelect.includes(option.key) && wrongAnswer(option.key) && pageType == 'check'"
				src="@/assets/images/ico_wrong@2x.png"
				mode=""
			/>
			<img
				v-else-if="userSelect.includes(option.key)"
				src="@/assets/img/ico_rili_xuanzhong@2x.png"
				mode=""
			/>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		option: {
			type: Object,
			default: () => ({ type: 0, title: '' })
		},
		testType: {
			type: Number,
			default: 0 // 0 答题 1 背题 2 顺序答题 3 查看试卷
		},
		question: {
			type: Object,
			default: () => ({})
		},
		orderType: {
			type: Number,
			default: 0 // 顺序刷题的两种状态 答题模式 0	背题模式 1
		},
		userSelect: {
			type: Array,
			default: () => []
		},
		index: {
			type: Number,
			default: 0
		},
		pageType:{
			type:String,
			default:'do'
		}
	},
	created(){
	},
	data() {
		return {
			canChangeList: [0, 2],
			optionTxtList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
		};
	},
	computed: {
		wrongAnswer(){
			return function(key){
				if(!this.question.answer) return false
				if(!this.question.answer.includes(key)){
					return true
				}
				return false
			}
		}
	},
	methods: {
	handleClick() {
			this.$emit('click');
	}
	}
};
</script>
<style lang="scss" scoped>
@import url('../../../../css/flex.scss');
.option {
	padding: 14px 16px;
	font-size: 16px;
	background: #ffffff !important;
	border-radius: 4px;
	margin: 0 10px 10px;
	position: relative;
	&.act {
		background-color: #d1f1e6 !important;
		border: #24AE81 1px solid;
		// color: #0bb65d;
	}
	&.did {
		background-color: #FDECE7 !important;
		border: #F34718 1px solid;
		// color: #0bb65d;
	}
	> text {
		color: #888;
		word-break: break-all;
	}
	&-cont {
		padding: 0 34px 0 10px;
		> img {
			width: 80px;
			height: 80px;
			&.mt20{
				margin-top: 10px;
			}
		}
	}
	> img {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 14px;
		right: 16px;
	}
}
</style>
