<template>
  <div>
    <div class="tit tit_fh">
      <div
        @click="goOff()"
        class="img"
        style="width: 22px; height: 22px; padding: 10px 22px 0px"
      >
        <img
          style="width: 26px"
          src="../../assets/images/ico_fanhui.png"
          alt=""
        />
      </div>
      <h1 style="width: 85px;margin: -20px 160px;">我的评测</h1>
    </div>
    <div style="height:44px"></div>
    <div class="PingTestItem" v-for="(item,index) in classComment" :key="index">
      <div class="pingTestTopBox">
        <div class="pingTestTitle">{{item.name}}</div>
        <div class="" style="color:#9697a5;"> {{item.modify_time}}</div>
      </div>
      <div class="pingTestContent">
        {{item.comment}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            classComment:[]
        }
    },
    created(){
       this.myClassComment()
    },
    methods:{
        goOff () {
          this.$router.go(-1)
        },
        myClassComment(){
            const data = {
 
            }
            this.$api.post('/stu/course/myClassComment', data)
            .then(res => {
            if (res.data.code === 200 && res.data.data) {
                this.classComment = res.data.data.list
            }
            })
        }
    }
};
</script>

<style scoped>
.tit {
  width: 100%;
  height: 44px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
}

.tit h1 {
  float: left;
  width: 100px;
  height: 24px;
  font-size: 17px;
  font-weight: 600;
  color: #101010;
  line-height: 24px;
  margin: 10px 150px 0;
}
.PingTestItem {
  margin-top: 10px;
  display: block;
  padding: 10px;
  background-color: #f7f8fa;
}

.pingTestTopBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pingTestTitle {
  font-size: 14px;
  font-weight: bold;
  color: #323240;
}
.pingTestContent {
  margin-top: 5px;
  font-size: 13px;
  color: #9697a5;
}
</style>