<template>
    <div class="nav-wrap">
        <img @click="back" class="back-icon" src="@/assets/images/ico_fanhui@2x.png" alt="">
        <div class="title">{{ title }}</div>
    </div>
</template>
<script>
export default {
   data() {
      return {
      }
   },
   props:['title'],
   created(){
   },
   computed:{
   },
   methods:{
    back(){
      this.$emit('handleBack')
    }
   },
}
</script>
<style lang="scss" scoped>
.nav-wrap{
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
  .back-icon{
    width: 22px;
    height: 22px;
    line-height: 50px;
  }
  .title{
    margin: auto;
    transform: translateX(-16px);
    font-size: 17px;
    font-weight: 600;
    color: #101010;
  }
}
</style>
