import { render, staticRenderFns } from "./zhucezh.vue?vue&type=template&id=11463037&scoped=true&"
import script from "./zhucezh.vue?vue&type=script&lang=js&"
export * from "./zhucezh.vue?vue&type=script&lang=js&"
import style0 from "./zhucezh.vue?vue&type=style&index=0&id=11463037&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11463037",
  null
  
)

export default component.exports