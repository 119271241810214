import router from './index'
import api from '@/api/onlineSchool'
import Vue from 'vue'

router.beforeEach(async (to, from, next) => {
    console.log(to, from, next);
    if(to.name === from.name) {
        return
    }
    if(to.name === 'home') {
        const res = await api.post('/common/index/getOrgInfo',{
            domain: getDomain()
        })
        if(res.data.code === 200 && res.data.data.link) {
            // 判断用户是否使用魔方网校主页作为网校首页
            Vue.prototype.$MO_FANG_URL = res.data.data.link
            next({
                name: 'CourseHome',
                query: { requestUrl: res.data.data.link }
            })
        } else {
            next()
        }
    } else {
        // 解决同路由跳转无法刷新的问题
        if(to.name === 'CourseHome' && from.name === 'CourseHome' && to.query.requestUrl !== from.query.requestUrl) {
            if(!to.query.id && !from.query.id) {
                window.location.replace(`${window.location.origin}/#/course-home?requestUrl=${to.query.requestUrl}&id=${new Date().valueOf()}`)
            } else {
                history.replaceState({}, '', window.location.href)
            }
            window.location.reload()
        } else {
            next()
        }
    }
})

function getDomain() {
	if(process.env.NODE_ENV == 'development'){
	   return process.env.VUE_APP_DOMAIN_URL
	} else {
       return window.location.host
    }
}