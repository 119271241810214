<template>
  <div>
    <NavigateBar :title="'历史观看'" @handleBack="handleBack"/>
    <div class="course">
        <div v-for="item in lista" :key="item.id" class="coursebx" @click="tostudy(item)">
        <img class="tuimgs" :src="item.img" alt="" />
        <div class="centon">
            <div class="shujuzhibiaos">{{item.name}}</div>
            <p class="ylook">已观看到 {{ transformation(item.view_time) }}</p>
            <div class="progressbx">
                <van-progress :percentage="item.percent" :show-pivot="false" /><span class="bfb">{{item.percent}}%</span>
            </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import NavigateBar from '../myHomework/navigateBar.vue';
export default {
    data(){
        return{
            lista:[],
            pageSize:'',
            page:1,
        }
    },
    components:{
        NavigateBar
    },
    created(){
       
    },
    mounted(){
        window.addEventListener('beforeunload', e => this.historyinit(e),true)
        window.addEventListener('scroll',this.handleScroll,true) 
        setTimeout(()=>{
          this.historyinit()
        },200)
    },
    destroyed(){
        window.removeEventListener('beforeunload', e => this.historyinit(e),true)
    },
    beforeDestroy(){
        window.removeEventListener("scroll",this.handleScroll,true)
    },
    methods:{
        tostudy(item){
            console.log(item);
            this.$router.push({
                path:'/zhibo',
                query:{
                    setid:item.course_id,
                    class_id:item.class_id,
                    view_time:item.view_time,
                    video_id:item.video_id,
                    chapter_id:item.chapter_id,
                    sectionId:item.sectionId,
                    name:item.name,
                    // sectionId:438
                }
            })
        },
        transformation(duration) {
            let g = duration;
            let m = parseInt((duration / 60) % 60);
            let s = duration % 60;
            if (s <= 9) {
              s = "0" + s;
            }
            if (m <= 9) {
              m = "0" + m;
            }
            if (g >= 3600) {
              g = g / 3600;
              g = parseInt(g);
              g = "0" + g + ":";
            } else {
              g = "";
            }
            return g + m + ":" + s;
        },
        handleBack(){
            this.$router.go(-1)
        },
        async historyinit(){
            const data = {
                page:this.page,
                page_nums:10
            }
            const res = await this.$api.post('/user/video/history',data)
                console.log(res.data.data.data,'this.pageSize');
                this.pageSize = (res.data.data.count>10?Math.ceil(res.data.data.count/10):1)
                this.lista = [...this.lista, ...res.data.data.data]
        },
        handleScroll(){
            let dom = window.document.documentElement || window.document.body
            //文档内容实际高度（包括超出视窗的溢出部分）
            let scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight);
            //滚动条滚动距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            //窗口可视范围高度
            let clientHeight = dom.innerHeight || Math.min(dom.clientHeight,dom.clientHeight);
            if(clientHeight + scrollTop+10 >= scrollHeight){
                if(this.page >= this.pageSize ) return
                this.page++
                this.historyinit()
            }
        },
    }
}
 
</script>

<style scoped lang="scss">
.course {
  border-radius: 10px;
  overflow: hidden;
  margin-left: 0.27rem;
  margin-right: 0.27rem;
  margin-top: 0.27rem;
}
.coursebx{
  display: flex;
  margin-bottom: .625rem;
  align-items: center;
}
.tuimgs {
  width: 2.5rem;
  height: 2.5rem;
  border-radius:.3125rem;
}
.bfb{
    position: absolute;
    top: -5px;
    right: 20px;
}
.centon{
  flex: 1;
  margin-left: 10px;
}
::v-deep .van-progress{
  width: 80%;
}
.ylook{
  margin-bottom: 10px;
  // margin-top: 37px;
  font-size: 14px;
}
.shujuzhibiaos{
  word-break:keep-all;
  white-space:normal;
  overflow:hidden;
  text-overflow:ellipsis;
  font-size: 0.40rem;
  font-weight: 600;
}
.progressbx{
  width: 100%;
  position: relative;
}
</style>