<template>
	<div class="question">
		<span class="num">{{`(${cur}/${total})`}}</span>
		<span class="title">{{ titTypeObj[question.type] }}：</span>
		{{ question.title }}
	</div>
</template>

<script>
export default {
	props: {
		question: {
			type: Object,
			default: () => ({ type: 0, title: '' })
		},
		cur:{
			type:Number,
			default:()=>{return 0}
		},
		total:{
			type:Number,
			default:()=>{return 0}
		}
	},
	// 0判断题	1单选题	2多选题	3简答题
	data() {
		return {
			titTypeObj: {
				3: '判断题',
				1: '单选题',
				2: '多选题',
				4: '简答题'
			}
		};
	},
};
</script>

<style lang="scss" scoped>
.question {
	padding: 0 10px;
	font-size: 16px;
	color: #202020;
	line-height: 28px;
	word-break: break-all;
	font-weight: 700;
}
</style>
