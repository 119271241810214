<template>
    <div>
        <div style="display: flex;padding-top: 20px;overflow: hidden;" @click="goOff()">
            <van-icon name="arrow-left" size="1.5em" />
            <span style="font-weight: 600;color: #000;">店铺信息</span>
        </div>
        <van-cell-group style="margin-top: 20px;">
            <van-cell title="企业名称" :value="org_name" />
            <van-cell title="营业执照" is-link @click="yulantupian()" />
        </van-cell-group>
    </div>
</template>
<script>
import { ImagePreview } from "vant";
import { mapGetters } from 'vuex'
export default {
    name: 'StoreinFormation',
    computed:{
    ...mapGetters({
        org_id:'user/getOrgId'
       })
   },
    data() {
      return {
        images: '',
        org_name: ''
      }  
    },
    created(){
            this.storedata()
		},
		methods: {
            storedata() {
                // 获取商户营业执照
                const data = {
                    uid: this.org_id
                }
                this.$api.post('https://liveapi2.tenghuiwangxiao.cn/api/store_data', data)
                .then(res=>{
                    if(res.data.code == 200){
                    this.images = res.data.data.business_img
                    this.org_name = res.data.data.org_name
                    }
                })
            },
            goOff(){
                this.$router.go(-1);
            },
            yulantupian() {
                ImagePreview([this.images]);
                console.log('111', this.org_id)
            },
		}
}
</script>