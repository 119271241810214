<template>
    <div>
        <div style="min-width: 362px;" class="all">
  <div class="head">
      <div class="head_t">
        <div>
              <div class="touxiang">
              <img style="width:1.81rem;height: 1.81rem;;border-radius: 100%;" :src="icon" alt="">
            </div>
            <div class="i_name">
              <span>{{nick_name}}</span>
              <span>班级门数: {{classNum}}</span>
          </div>
        </div>
       <!-- <div>
         <div class="touxiang">
          <img style="width: 68px;height: 66px;border-radius: 34px;" src="../../assets/images/touxianger.png" alt="">
        </div>
        <div class="i_name">
            <router-link :to="{path:'zhanghaomimadl',query:{setid:123123}}">
          <p class="denglut">登录/注册</p>
           </router-link>
        </div>
       </div> -->
       <router-link :to="{path:'ziliao',query:{setid:123123}}">
      <div class="bianji">
        <span>编辑资料</span>
      </div>
      </router-link>
      <!-- <div class="time">
        <span>今日学习分钟</span>
        <span>60</span>
        <span>学习天数</span>
        <span>365</span>
        <span>剩余课程点</span>
        <span>12</span>
      </div> -->
      <div class="title">
        <h3 style="position: absolute;top: 215px;font-size: 0.43rem;font-weight: 600;color: #101010;">我的功能</h3>
      </div>
      </div>
      <!-- 111 -->

  </div>
  <div class="head_b">
      <!-- <router-link :to="{path:'kechengchongzhiA',query:{setid:123123}}">
      <div class="gongneng">
        <img src="../../assets/images/ico_kechengdian.png" alt="" >
        <a href="#" style="padding: 20px;">课程点充值</a>
      </div>
      </router-link>
      <router-link :to="{path:'myshoucangA',query:{setid:123123}}">
      <div class="gongneng">
        <img src="../../assets/images/ico_shoucang.png" alt="">
        <a href="#">我的收藏</a>
      </div>
      </router-link>
       <router-link :to="{path:'sheZhiA',query:{setid:123123}}">
      <div class="gongneng">
        <img src="../../assets/images/ico_shezhi.png" alt="" >
        <a href="#" style="padding: 18px 38px;">设置</a>
      </div>
      </router-link> -->
      <router-link :to="{path:'pingtest',query:{setid:123123}}">
       <van-cell is-link class="gongnenger">
        <img src="../../assets/images/pingtest.png" alt="">
        <p>我的评测</p>
      </van-cell>
      </router-link>
      <router-link :to="{path:'HistoryViewing'}">
        <div class="gongneng">
          <img src="../../assets/images/aglook.png" alt="">
          <a href="#">历史观看</a>
        </div>
      </router-link>
       
      <router-link :to="{path:'homework'}">
        <div class="gongneng">
          <img src="../../assets/images/ico_homework@2x.png" alt="">
          <a href="#">我的作业</a>
        </div>
      </router-link>
      <van-cell is-link @click="showPopup" class="gongnenger">
        <img src="../../assets/images/kefuertu.png" alt="">
        <p href="#">客服热线</p>
      </van-cell>
      <router-link :to="{path:'guanyu',query:{setid:123123}}">
        <div class="gongneng">
          <img src="../../assets/images/guanyuertu.png" alt="">
          <a href="#">关于我们</a>
        </div>
      </router-link>
      <van-popup style="width: 280px;height: 170px;background: #ffffff;border-radius: 10px;" v-model="show">
        <p class="kefu">客服电话</p>
        <p class="kefudianhua" v-if="web_contact_number">{{web_contact_number}}</p>
        <div style="margin-top: 23px;">
          <span @click="gitList()" class="quxiao">取消</span>
          <span @click="callPhone" class="lijiboda">立即拨打</span>
        </div>
      </van-popup>
    </div>
</div>
<!-- 尾部 -->
    <div style="margin-top: 48px;">
      <van-tabbar active-color="#10A675" v-model="active">
        <van-tabbar-item icon="home-o" :to="{path:'/'}">首页</van-tabbar-item>
        <van-tabbar-item icon="orders-o" :to="{path:'myStudy',query:{setid:123123}}">学习</van-tabbar-item>
        <van-tabbar-item icon="smile-o" :to="{path:'my',query:{setid:123123}}">我的 </van-tabbar-item>
      </van-tabbar>
    </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { Popup } from 'vant'

Vue.use(Popup)
export default {
  data () {
    return {
      show: false,
      active: 3,
      nick_name: '',
      icon: '',
      classNum: '',
      web_contact_number:'',
    }
  },
  created () {
    this.getUserInfo()
    this.web_contact_number = localStorage.getItem('web_contact_number')
  },
  methods: {
    callPhone () {
      window.location.href = 'tel://' + this.web_contact_number
    },
    gitList () {
      this.show = false
    },
    showPopup () {
      this.show = true
    },
    // handleClose (done) {
    // },
    getUserInfo () {
      // 获取用户信息
      const data = {

      }
       this.$api.post('/user/index/getUserInfo', data)
        .then(res => {
          if (res.data.code === 200) {
            this.$store.commit("user/setFansId",res.data.data.id)
            localStorage.setItem('tengface_fans_id', res.data.data.id)
            this.nick_name = res.data.data.nick_name
            this.icon = res.data.data.icon
            this.classNum = res.data.data.classNum
          }
        })
    }
  }
}
</script>
<style scoped>
.lijiboda{
background: linear-gradient(95deg,#61d1ac 4%, #10a675 95%);
border-radius: 17px;
font-size: 14px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
color: #ffffff;
padding: 8px 26px;
margin-left: 14px;
}
.quxiao{
  font-size: 14px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
color: #101010;
background: #efefef;
border-radius: 17px;
padding: 8px 37px;
margin-left: 26px;
}
.kefudianhua{
  font-size: 28px;
font-family: DINPro, DINPro-Bold;
font-weight: 700;
text-align: left;
color: #101010;
text-align: center;
margin-top: 8px;
}
.kefu {
  font-size: 16px;
font-family: PingFangSC, PingFangSC-Regular;
font-weight: 400;
text-align: left;
color: #101010;
text-align: center;
margin-top: 24px;
}
.gongnenger >>> .van-icon-arrow:before{
  content:none
}
.tttt >>> .el-dialog__footer{
 padding: 10px 41px 28px;
}
.kefu{
  font-size: 16px;
  color: #101010;
}
.kefuyi{
   font-size: 28px;
  font-weight: 700;
  color: #101010;
}
.quxiao {
  width: 100px;
  height: 32px;
  background: #efefef;
  border-radius: 17px;
  font-size: 14px;
  font-weight: 400;
  color: #101010;
}
.boda {
  width: 100px;
  height: 32px;
  background: linear-gradient(95deg, #61d1ac 4%, #10a675 95%);
  border-radius: 17px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: PingFangSC, PingFangSC-Regular;
  background: #f6f6f6;

}

a {
  text-decoration: none;
}

.all {
  position: relative;
  width: 100%;
  height: 619px;
}

.head {
  float: left;
  width: 100%;
  height: 250px;
  background-image: linear-gradient(to bottom, #C5EEE5, #E8F4EF);
  backdrop-filter: blur(49px);

}

.head_t {
  float: left;
  width: 100%;
  height: 70px;
  margin-top: 46px;
}

.head_t .touxiang {
  float: left;
  /* background: #10a675; */
  /* border: 4px solid #ffffff; */
  margin-left: 20px;
}

.i_name {
  float: left;
  width: 2.4rem;
  height:0.67rem;
  margin-left: 0.53rem;
}

.i_name span:first-child {
  float: left;
  width: 2.4rem;
  height: 0.67rem;
  font-size: 0.48rem;
  font-weight: 600;
  color: #101010;
  line-height: 25px;
}

.i_name span:last-child {
  float: left;
  width: 2.32rem;
  height: 0.75rem;
  text-align: center;
  font-size: 14px;
  line-height: 0.75rem;
  color: #10a675;
  border: 0.01rem solid #10a675;
  border-radius: 0.43rem;
  margin-top: 9px;
}

.bianji {
    float: right;
    width: 1.92rem;
    height: 0.75rem;
    background: linear-gradient(95deg, #61d1ac 4%, #10a675 95%);
    border-radius: 16px;
    margin-top: 15px;
    margin-right: 18px;
    text-align: center;
    line-height: 0.75rem;
}

.bianji span {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    margin-top: 4px;
}

.time {
  float: left;
  width: 100%;
  height: 25px;
  margin-top: 25px;
}

.time span:nth-child(odd) {
  width: 72px;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #7e8180;
  line-height: 22px;
  margin-left: 20px;
}

.time span:nth-child(even) {
  width: 20px;
  height: 22px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #101010;
  line-height: 22px;
}

.title {
  /* float: left; */
  width: 75px;
  height: 22px;
  margin-top: 30px;
  margin-left: 20px;
}

.title span {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #101010;
  line-height: 22px;
}

.head_b {
  float: left;
  width: 100%;
  height: calc(100vh - 250px);
  background: #f6f6f6;

}

.head_b .gongneng {
  float: left;
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px #f2f8f6;
  margin-left: 18px;
  margin-top: 20px;
}
.gongnenger {
  float: left;
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px #f2f8f6;
  margin-left: 18px;
  margin-top: 20px;
  text-align: center;
}
.gongnenger >>> .van-cell__value--alone{
  text-align: center;
}
.gongnenger img {
  margin-top: 18px;
  width: 30px;
  height: 30px;
}
.gongnenger p {
  margin-top: 6px;
}
.head_b .gongneng img {
  width: 30px;
  height: 30px;
  margin: 15px 35px;
}

.head_b .gongneng a {
    font-size: 13px;
    font-weight: 400;
    color: #101010;
    line-height: 20px;
    padding: 23px;
}
.head_b .gongnengyi {
  float: left;
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px #f2f8f6;
  margin-left: 18px;
  margin-top: 20px;
}
.head_b .gongnengyi img {
  width: 30px;
  height: 30px;
}

.head_b .gongnengyi p {
  width: 60px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #101010;
  line-height: 20px;
  margin-left: 20px;
  margin-top: 15px;
}

.denglut{
  font-size: 18px;
font-family: PingFangSC, PingFangSC-Semibold;
font-weight: 600;
margin-top: 25px;
color: #101010;
}
</style>
