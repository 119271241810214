<template>
  <div class="submit-layout">
    <img src="@/assets/images/ico_wancheng@2x.png" />
    <div class="title">恭喜您完成本次作业</div>
    <div class="sub-title">本次部分得分</div>
    <div class="score">
      {{ getScore }}<span>分</span>
    </div>
    <div class="tips">简答题分数待老师批阅后可查看</div>
    <div class="feedback-btn">
      <button @click="comfirmBack">返回</button>
    </div>
    <!-- <img class="feedback-close" @click="handleCloseSubmitPaper" src="../img/ico_guanbi2@2x.png" /> -->
  </div>
</template>

<script>
export default {
  data () {
    return { }
  },
  props:['totalScore','getScore'],
  computed: { },
  watch: { },
  methods: {
    comfirmBack(){
      this.$router.go(-1)
    }
   },
  mounted() {
    
   }
}
</script>

<style lang="scss" scoped>
.submit-layout{
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
    margin-bottom: 29px;
    margin-top: 86px;
  }
  .title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #000000;
  }
  .sub-title{
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
    color: #888888;
  }
  .score{
    font-size: 36px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 37px;
    span{
      font-size: 14px;
      font-weight: 400;
      font-size: 14px;
      color: #888888;
    }
  
  }
  .tips{
    color: #F34718;
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  .feedback-btn{
    button{
      width: 210px;
      height: 40px;
      background: #10a675;
      border-radius: 22px;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      line-height: 40px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>
