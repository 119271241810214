<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'App',
  created(){
	this.domain = window.location.host
	// this.domain = "wx-aboh.tengface.cn"
	if(process.env.NODE_ENV == 'development'){
		this.domain = process.env.VUE_APP_DOMAIN_URL
	}
	//获取token , 路由地址为/时无法取到token
	let token = this.$route.query.token
	
	if (location.hash.indexOf("token") > -1) {
		token = location.hash.split('token=').pop()
	}
	if (token) {
		localStorage.setItem('token',token)
	}
	this.getOrgInfo()
	this.getOrgBaseInfo()
  },
  methods:{
	getOrgBaseInfo(){
		this.$api.get(`/common/index/getWebBaseInfo?key=${this.domain}`).then((res)=>{
			if(res.data.code == 200){
				document.title = res.data.data.web_company_name
				localStorage.setItem("web_contact_number", res.data.data.web_contact_number || 'XXX-XXX-XXX')
			}
		}).catch((error)=>{
			console.log(error);
		});
	},
	// 域名获取商户id
    async getOrgInfo () {
		return new Promise((resolve, reject)=>{
			const data = {
				domain: this.domain
			}
			this.$api.post('/common/index/getOrgInfo',data).then((res)=>{
				if(res.data.code == 200){
					this.$store.commit("user/setMoFangUrl", res.data.data.link)
					localStorage.setItem('tengface_mofang_url', res.data.data.link)
					this.$store.commit("user/setOrgId",res.data.data.uid)
					if(`${localStorage.getItem('tengface_org_id')}` !== `${res.data.data.uid}`){
						localStorage.setItem('tengface_org_id',res.data.data.uid)
						// uid更新需要刷新重新加载数据
						location.reload()
					}
					// if(res.data.data.link && this.$route.name !== 'CourseHome' && this.$route.name !== 'zhanghaomimadl') {
					// 	// 判断用户是否使用魔方网校主页作为网校首页
					// 	this.$router.push({ name: 'CourseHome', query: { requestUrl: res.data.data.link } })
					// }	
				}
				resolve()
			}).catch((error)=>{
				reject(error)
			});
		})
    }
  }
}
</script>

<style>
html,body,#app{
	width: 100%;
	height: 100%;
}
</style>
