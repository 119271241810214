<template>
    <div >
        <div style="display: flex;margin-top: 20px;" @click="goOff()">
          <van-icon name="arrow-left" size="1.5em" />
          <span style="font-weight: 600;color: #000;">公司信息</span>
        </div>
        <div class="chakang">您即将查看公司信息</div>
        <div class="baozheng">为了保证双方信息安全，请先按照提示完成安全验证</div>
        <div class="quyanz"  @click="show = true">去验证</div>
        <van-popup v-model="show" :close-on-click-overlay="false">
          <div class="yanzhengma">
             <p @click="show = false" class="guanbi">X</p>
             <div class="anquan" style="text-align: center;">
                <p>安全验证</p>
             </div>
             <div class="yanzhengmade">
                <input class="shuruyanz" type="text" v-model="inputCode" placeholder="请输入下图验证码，不区分大小写">
                <div style="margin-top: 10px;">
                    <!--验证码组件-->
                    <VerifyStyle :identifyCode="identifyCode"></VerifyStyle>
                    <van-icon name="replay" size="2em" style="position: absolute;right: 15px;margin-top: 16px;" @click="refreshCode()" />
                    <!-- <p >切换</p> -->
                </div>
                <button class="tijiaoanniu" type="primary" @click="getSubmitData()">提交</button>
             </div>
          </div>
        </van-popup>
    </div>
</template>
<script>
import VerifyStyle from "./VerifyStyle.vue";
import { Toast } from 'vant';
import { mapGetters } from 'vuex'
export default {
   name: 'looKover',
   computed:{
    ...mapGetters({
        org_id:'user/getOrgId'
       })
   },
  components: { VerifyStyle },
    data() {
        return {
            show: false,
            identifyCodeType: "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ", //定义验证类型 1.数字 2.字母
            identifyCode: "",
            inputCode: "", //text框输入的验证码
        }
    },
    created() {
        console.log('hhhhhjjjjjj-----------', this.org_id)
    },
    mounted() {
    this.refreshCode();
  },
    methods: {
    goOff(){
        this.$router.go(-1);
    },
    //验证码规则
    getSubmitData() {
      if (this.inputCode == "") {
        Toast.fail('请输入验证码');
        return;
      }
      let num = this.inputCode.toUpperCase()
      if (this.identifyCode !== num) {
        this.inputCode = "";
        this.refreshCode();
        Toast.fail('请输入正确验证码');
        return;
      } else {
        this.inputCode = "";
        this.refreshCode();
        Toast.success('验证成功');
        this.$router.push({'path':'/Storeinformation'});
      }
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    //初始化验证码
    refreshCode() {
      this.identifyCode = ""; //输入框置空
      this.makeCode(this.identifyCodeType, 4); //验证码长度为4
    },
    //随机切换验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodeType[
            this.randomNum(0, this.identifyCodeType.length)
          ];
      }
    //   console.log(this.identifyCode);
    },     
   },
}
</script>
<style scoped>
.tijiaoanniu {
    width: 130px;
    height: 30px;
    background: #0066FF;
    border: none;
    color: #fff;
    border-radius: 20px;
    margin-top: 20px;
}
.shuruyanz {
    width: 220px;
    height: 40px;
    border: 1px silver solid;
}
.yanzhengmade {
    text-align: center;
    margin-top: 20px;
}
.anquan p {
    color: #000;
    font-weight: 600;
}
.anquan {
    text-align: center;
}
.guanbi {
    text-align: right;
    margin-right: 10px;
    font-size: 14px;
}
  .yanzhengma { 
    height: 252px;
    width: 325px;

  }
  .chakang {
    text-align: center;
    color: #000;
    margin-top: 20px;
  }
  .baozheng {
    text-align: center;
    color: #A9A9A9;
    margin-top: 20px;
  }
  .quyanz {
    width: 240px;
    height: 40px;
    background: #32CD32;
    margin: 0 auto;
    border-radius: 20px;
    margin-top: 20px;
    text-align: center;
    line-height: 40px;
    color: #fff;
  }
</style>