<template>
    <div>
        <div class="tit">
          <!-- <div class="img" @click="goOff" style="float: left;width: 22px;height: 46px;padding: 15px 22px 0;">
              <a href="#">
                <img style="width: 0.59rem;height: 0.59rem;" src="../../assets/images/ico_fanhui.png" alt="">
              </a>
          </div> -->
          <div class="img" @click="goOff" style="position: absolute;top: 12px;left: 10px;">
            <img style="width: 0.59rem;height: 0.59rem;" src="../../assets/images/ico_fanhui.png" alt="">
          </div>
          <h1 style="font-size: 0.45rem;font-weight: 600;color: #101010;">关于我们</h1>
        </div>
        <div class="keji">
            <div class="logo"><img src="../../assets/images/ico_logotenghui.png" alt=""></div>
            <div class="tenghui"><span>腾慧科技</span></div>
            <div class="banben"><span>v2.02.0</span></div>
            <div class="jianjie"><p>腾慧网校互动课堂可以满足多人人互动连麦教学需 求，深度覆盖多个教学应用场景。支持百人进行在线实时互动，课件展示、多媒体展示、桌面共享、讨论区答疑互动等功能组件，多角度还原线下授课场景；</p></div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'guanYu',
  data () {
    return {

    }
  },
  methods: {
    goOff () {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
   * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: PingFangSC, PingFangSC-Regular;
  background: #f6f6f6;

}

a {
  text-decoration: none;
}

.keji{
    max-width: 640px;
}



.tit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: #ffffff;
  max-width: 448px;
}

.tit h1 {
  text-align: center;
  /* float: left;
  margin-left: 3.09rem;
  margin-top: 0.24rem; */
}

.logo img {
  width: 2.53rem;
  height: 2.53rem;
  margin-top: 1.01rem;
  margin-left: 3.73rem;
}

.tenghui {
  font-size: 0.48rem;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #202020;
  letter-spacing: 0.02rem;
  margin-left: 4rem;
  margin-top: 0.29rem;
}

.banben {
  font-size: 0.37rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #888888;
  letter-spacing: 0.05rem;
  margin-left: 4.16rem;
  margin-top: 0.13rem;
}

.jianjie {
 width: 8.93rem;
 height: 2.99rem;
 margin-left: 0.53rem;
 margin-top: 0.8rem;
}
.jianjie p{
  font-size: 0.37rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: #202020;
}
</style>
